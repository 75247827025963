<template>
  <v-card flat color="transparent" class="pa-0">
    <previewheader-component :title="titleText" @back="close" />
    <v-card>
      <v-card class="pa-4" flat>
        <v-card flat>
          <v-form>
            <v-row>
              <v-col cols="12" md="6">
                <v-card class="pa-4" flat>
                  <v-row justify="space-between">
                    <v-col cols="6">
                      <v-card-subtitle class="pa-0 text-start">
                        <strong>
                          {{ $t("object.invoice.billTo") }}
                        </strong>
                      </v-card-subtitle>
                    </v-col>
                    <v-col cols="6">
                      <statusDisplayComponent
                        style="width: fit-content; justify-self: end"
                        :text="
                          $t('object.invoice.msg.percentDisc', [
                            formItems?.serviceProvider?.percentage,
                          ])
                        "
                        color="transparent"
                        textColor="primary"
                        icon="mdi-information"
                        v-if="
                          formItems.serviceProvider?.id &&
                          formItems.serviceProvider?.percentage
                        "
                      />
                    </v-col>
                  </v-row>
                  <v-select
                    clear-icon="mdi-close-circle-outline"
                    label="Select Service Provider"
                    v-model="serviceProvider"
                    filled
                    outlined
                    :disabled="!!formItems.id && !!formItems.serviceProvider.id"
                    :items="serviceProviderList"
                    return-object
                    clearable
                    item-text="firstName"
                  />
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        hide-details
                        v-model="formItems.serviceProvider.firstName"
                        outlined
                        :disabled="disableSPField"
                        filled
                        :label="$t('object.person.firstName')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="formItems.serviceProvider.lastName"
                        :disabled="disableSPField"
                        hide-details
                        outlined
                        filled
                        :label="$t('object.person.lastName')"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formItems.serviceProvider.address"
                        :disabled="disableSPField"
                        hide-details
                        outlined
                        filled
                        :label="$t('object.person.address')"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :disabled="disableSPField"
                        hide-details
                        v-model="formItems.serviceProvider.city"
                        outlined
                        filled
                        :label="$t('object.person.city')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        hide-details
                        :disabled="disableSPField"
                        :rules="formRules.default"
                        v-model="formItems.serviceProvider.postalCode"
                        filled
                        outlined
                        :label="$t('object.person.postalCode')"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="pa-4" flat>
                  <v-card-subtitle class="text-start pa-0">
                    <strong>
                      {{ $t("object.invoice.details") }}
                    </strong>
                  </v-card-subtitle>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="date"
                        v-model="formItems.invoiceDate"
                        outlined
                        filled
                        :label="$t('object.invoice.invoiceDate')"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="formItems.paymentDue"
                        filled
                        outlined
                        :label="$t('object.invoice.paymentDue')"
                        type="date"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        hide-details
                        outlined
                        filled
                        clearable
                        no-resize
                        auto-grow
                        rows="1"
                        v-model="formItems.description"
                        :rules="formRules.description"
                        :label="$t('object.invoice.prodDecription')"
                      />
                    </v-col>
                  </v-row>
                </v-card>
                <v-scroll-x-transition
                  leave-absolute
                  v-if="openTasks.length > 0"
                >
                  <v-card flat class="px-4">
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="primary--text">
                          {{ $t("object.invoice.openTask") }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card v-if="openTasks?.length > 0">
                            <!-- <v-slide-y-transition class="py-0"> -->
                            <v-list-item
                              v-for="task in openTasks"
                              :key="task.id"
                            >
                              <v-list-item-action>
                                <v-checkbox
                                  v-model="task.existsInFormItems"
                                  @change="emitTask(task)"
                                  :color="
                                    (task.existsInFormItems && 'grey') ||
                                    'primary'
                                  "
                                >
                                  <template v-slot:label>
                                    <div
                                      :class="
                                        (task.existsInFormItems &&
                                          'grey--text') ||
                                        'primary--text'
                                      "
                                      class="ml-4"
                                      v-text="task.ticketNumber"
                                    ></div>
                                  </template>
                                </v-checkbox>
                              </v-list-item-action>
                              <v-spacer></v-spacer>

                              <v-scroll-x-transition>
                                <v-icon
                                  color="success"
                                  v-if="task.existsInFormItems"
                                >
                                  mdi-check
                                </v-icon>
                              </v-scroll-x-transition>
                            </v-list-item>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card>
                </v-scroll-x-transition>
              </v-col>
            </v-row>

            <v-card class="pa-4 ma-2" flat v-if="openTasks.length > 0">
              <v-card-subtitle class="text-start pa-0">
                <strong>
                  {{ $t("object.label.orderList") }}
                </strong>
              </v-card-subtitle>
              <v-spacer />

              <v-card flat outlined>
                <v-data-table
                  :dense="$vuetify.breakpoint.mdAndDown"
                  :headers="taskHeaders"
                  :items="taskList"
                  hide-default-footer
                >
                  <template v-slot:[`item.invoicedAmount`]="{ item }">
                    <v-edit-dialog :return-value.sync="item.invoicedAmount">
                      <v-btn rounded text outlined>
                        {{ item.invoicedAmount }}
                      </v-btn>
                      <template v-slot:input>
                        <v-text-field
                          v-model="item.invoicedAmount"
                          label="Edit"
                          :hint="'€' + (item.invoicedAmount ?? '')"
                          single-line
                          counter
                        />
                      </template>
                    </v-edit-dialog>
                  </template>
                </v-data-table>
              </v-card>
            </v-card>
          </v-form>
          <v-card-actions v-if="formItems.id">
            <v-row dense align-content="end" justify="start" class="pa-4">
              <v-col cols="12" md="2" align-self="start">
                <v-btn
                  color="primary"
                  class="rounded-lg"
                  block
                  @click="createInvoice"
                  large
                  width="120"
                  style="text-transform: none"
                  :disabled="taskList.length < 1"
                >
                  <small>
                    {{ $t("site.btn.createNew") }}
                  </small>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-actions v-else>
            <v-row dense align-content="end" justify="start" class="pa-4">
              <v-col cols="12" md="2" align-self="start">
                <v-btn
                  color="primary"
                  class="rounded-lg"
                  block
                  @click="createInvoice"
                  large
                  width="120"
                  style="text-transform: none"
                  :disabled="taskList.length < 1"
                >
                  <small>
                    {{ $t("site.btn.createNew") }}
                  </small>
                </v-btn>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  color="primary"
                  block
                  outlined
                  large
                  @click="draft"
                  class="rounded-lg"
                  :disabled="taskList.length < 1"
                  width="120"
                  style="text-transform: none"
                >
                  <small> {{ $t("object.invoice.btn.saveDraft") }} </small>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import { formRules, FormRules } from "@/helpers/component-helper";
import {
  EnumInvoiceStage,
  Invoice,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model/dist";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { DataTableHeader } from "vuetify";
import PreviewheaderComponent from "../previewheader-component.vue";
import StatusDisplayComponent from "../statusdisplay-component.vue";

type DataType = {
  serviceProvider: ServiceProvider;
  editedIndex: null | number;
  stageEnum: typeof EnumInvoiceStage;
};
type ComputeType = {
  computeDrawer: boolean;
  formItems: Invoice;
  taskHeaders: DataTableHeader[];
  taskList: Task[];
  isChecked(task: Task): boolean;
  disableSPField: boolean;
  openTasks: (Task & { existsInFormItems: boolean })[];
  formRules: FormRules;
  titleText: TranslateResult | string;
};
type PropType = {
  value: Invoice;
  today: string;
  toggle: boolean;
  serviceProviderList: ServiceProvider;
  openServProvider: ServiceProvider[];
  defaultInvoice: Invoice;
};
type MethodType = {
  taskAdded: (task: Task) => boolean;
  editFormItem(item: Task): void;
  deleteFormItem(item: Task): void;
  close(): void;
  addEmptyTask(): void;
  createInvoice(): void;
  draft(): void;
  emitTask(task: Task): void;
  isTaskInForm(task: Task): boolean;
  taskExistsInServiceProvider(task: Task): boolean | undefined;
  taskExistsInFormItems(task: Task): boolean;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "InvoiceEntryComponent",
  components: {
    statusDisplayComponent: StatusDisplayComponent,
    PreviewheaderComponent,
  },
  data: () => ({
    serviceProvider: new ServiceProvider(),
    editedIndex: null,
    stageEnum: EnumInvoiceStage,
  }),
  props: {
    value: { type: Invoice },
    today: { type: String },
    toggle: { type: Boolean },
    serviceProviderList: { type: ServiceProvider },
    defaultInvoice: { type: Invoice },
    openServProvider: { type: Array },
  },
  watch: {
    serviceProvider(val) {
      this.formItems.serviceProvider = val;
      if (val === null) {
        this.formItems.serviceProvider = new ServiceProvider();
        this.formItems.tasks = [];
      }
    },
    formItems(val) {
      if (val.id) {
        this.serviceProvider = val.serviceProvider;
      }
    },
    toggle(val) {
      if (!val) {
        this.formItems = this.defaultInvoice;
        this.serviceProvider = new ServiceProvider();
      }
    },
  },
  computed: {
    formItems: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computeDrawer: {
      get() {
        return this.toggle;
      },
      set(value) {
        return this.$emit("change", value);
      },
    },
    formRules() {
      return formRules;
    },
    isChecked() {
      return (task) => {
        const existingInSP = this.taskExistsInFormItems(task);
        const existingTask = this.taskExistsInServiceProvider(task);
        return (existingInSP && existingTask) ?? false;
      };
    },
    taskHeaders() {
      return [
        {
          text: this.$i18n.t("object.task.header.ticketNo"),
          value: "ticketNumber",
        },
        {
          text: this.$i18n.t("object.invoice.invoiceAmount"),
          value: "invoicedAmount",
        },
        { text: this.$i18n.t("object.task.header.price"), value: "price" },
      ] as DataTableHeader[];
    },
    taskList() {
      return this.formItems?.tasks;
    },
    openTasks() {
      const selectedSP = this.openServProvider?.find(
        (x) => x.id === this.formItems.serviceProvider?.id
      );
      const openTasks = selectedSP ? selectedSP.tasks ?? [] : [];
      const formItemTasks = this.formItems.tasks ?? [];
      const uniqueTasks = new Set([...openTasks, ...formItemTasks]);

      const tasksWithCheck = openTasks.map((task) => {
        const existsInFormItems = formItemTasks.some(
          (item) => item.id === task.id
        );
        return {
          ...task,
          existsInFormItems: existsInFormItems,
        };
      });

      return tasksWithCheck;
    },
    disableSPField() {
      const isServProv =
        !!this.value.serviceProvider && !!this.value.serviceProvider?.id;
      return isServProv;
    },
    titleText() {
      if (this.formItems && this.formItems.id) {
        return this.$i18n.t("object.invoice.editInv");
      }
      return this.$i18n.t("object.invoice.newInv");
    },
  },
  methods: {
    taskAdded(task: Task) {
      const tasks = this.formItems.tasks;
      const isfound = tasks?.find((x) => x.id === task.id);
      return isfound ? true : false;
    },
    close() {
      this.formItems = new Invoice();
      this.$router.back();
      this.$emit("close");
    },
    addEmptyTask() {
      const task = {} as Task;
      if (this.formItems.tasks?.length === 0) {
        this.formItems.tasks = new Array<Task>();
      }
      this.formItems.tasks?.push(task);
    },
    createInvoice() {
      this.$emit("create");
    },
    draft() {
      this.$emit("draft");
    },
    emitTask(task: Task) {
      this.$emit("addTask", task);
    },
    editFormItem(item: Task): void {
      if (!item.id) {
        item.id = -1;
      }
    },
    deleteFormItem(item: Task): void {
      const index = this.taskList.indexOf(item);
      this.taskList.splice(index, 1);
    },
    isTaskInForm(task) {
      if (task && task.id) {
        return task.id in this.formItems.tasks;
      }
      return false;
    },
    taskExistsInServiceProvider(task: Task) {
      const selectedSP = this.openServProvider.find(
        (x) => x.id === this.formItems.serviceProvider?.id
      );
      return selectedSP && selectedSP.tasks?.some((t) => t.id === task.id);
    },
    taskExistsInFormItems(task: Task) {
      return (
        this.formItems.tasks &&
        this.formItems.tasks.some((t) => t.id === task.id)
      );
    },
  },
});
</script>

<style></style>
