<template>
  <span>
    <serviceproviderComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import ServiceProviderComponent from "../subcomponent/admin/serviceprovider-component.vue";

export default Vue.extend({
  name: "AdminComponent",
  components: {
    serviceproviderComponent: ServiceProviderComponent,
  },
});
</script>

<style></style>
