<template>
  <span>
    <previewHeaderComponent @back="$router.back()" class="mb-4" />
    <serviceproviderDetailsPageComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import ServiceproviderDetailsPageComponent from "../subcomponent/admin/serviceproviderdetailspage-component.vue";
import PreviewHeaderComponent from "../subcomponent/previewheader-component.vue";

export default Vue.extend({
  name: "ServiceProviderProfileComponent",
  components: {
    serviceproviderDetailsPageComponent: ServiceproviderDetailsPageComponent,
    previewHeaderComponent: PreviewHeaderComponent,
  },
});
</script>

<style></style>
