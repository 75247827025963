import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export class MsalService {
  private msalConfig: Configuration = {
    auth: {
      clientId: process.env.MSAL_CLIENTID ?? "",
      authority: process.env.MSAL_ATHORITY,
      redirectUri: process.env.MSAL_REDIRECTURI,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };
  protected msalInstance = new PublicClientApplication(this.msalConfig);

  private msalInitialize() {
    return this.msalInstance.initialize();
  }

  login() {
    return this.msalInitialize()
      .then(() => {
        return this.msalInstance.loginPopup({ scopes: ["user.read"] });
      })
      .then((val) => {
        console.log(val);
        return val;
      })
      .catch((err) => console.error(err));
  }

  logout() {
    return this.msalInitialize().then(() => {
      return this.msalInstance.logoutPopup();
    });
  }
}
