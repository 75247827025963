<template>
  <span>
    <loginComponent />
    <!-- <signupFormComponent /> -->
  </span>
</template>

<script lang="ts">
import LoginComponent from "@/components/auth/auth-component.vue";
import Vue from "vue";
export default Vue.extend({
  name: "AuthView",
  components: {
    loginComponent: LoginComponent,
    // signupFormComponent: SignupFormComponent,
  },
  data: () => ({}),
});
</script>

<style></style>
