import { setCookies } from "@/helpers/cookies-helper";
import "@mdi/font/css/materialdesignicons.css";
import "font-awesome/css/font-awesome.min.css";
import Vue from "vue";
import colors from "vuetify/es5/util/colors";
import Vuetify from "vuetify/lib/framework";
import { Color } from "vuetify/lib/util/colors";
import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export const storageThemeKey = "dark_theme";
export const storageKeyMainColor = "colorIndex";
export const colorMainBackground = "colormainbackground";
export const colorMenuTileBackground = "colormenutilebackground";

const DEFAULT_COLOR = colors.indigo;
const primaryLightColor = "#1976D2"; //colors.blue;
const primaryDarkColor = "#0E4A8B";
const mainLight = "#b1dbfc";
const mainDark = "#115293";

export const colorList: Color[] = [
  colors.indigo,
  colors.red,
  colors.green,
  colors.blue,
  colors.pink,
  colors.amber,
  colors.deepOrange,
  colors.deepPurple,
  colors.amber,
  colors.cyan,
  colors.teal,
  colors.lime,
  colors.yellow,
];

const vuetify = new Vuetify({
  // icons: {
  //   iconfont: "fa4",
  // },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: primaryLightColor,
        [colorMainBackground]: mainLight,
      },
      dark: {
        primary: primaryDarkColor,
        [colorMainBackground]: mainDark,
      },
    },
  },
  lang: {
    locales: { en, de },
  },
});

export default vuetify;

applyThemeColor(readThemeColorSetting());

export function setNewThemeColor(newColor: Color): void {
  let colorIndex: number;
  colorIndex = colorList.indexOf(newColor);
  if (colorIndex >= colorList.length || colorIndex < 0) {
    colorIndex = colorList.indexOf(DEFAULT_COLOR);
  }
  setCookies(storageThemeKey, colorIndex.toString());
  applyThemeColor(newColor);
}

export function readThemeColorSetting(): Color {
  const colorSetting = localStorage.getItem(storageKeyMainColor) ?? "0";
  if (colorSetting !== null) {
    const index = parseInt(colorSetting);
    return !isNaN(index) && index >= 0 && index < colorList.length
      ? colorList[index]
      : DEFAULT_COLOR;
  }
  return DEFAULT_COLOR;
}

function applyThemeColor(themeColor: Color): void {
  vuetify.framework.theme.themes.light = updateFromPartial(
    vuetify.framework.theme.themes.light,
    {
      primary: themeColor,
      secondary: themeColor.lighten1,
      accent: themeColor.darken2,
      [colorMenuTileBackground]: themeColor.lighten4,
      [colorMainBackground]: themeColor.lighten5,
    }
  );
  vuetify.framework.theme.themes.dark = updateFromPartial(
    vuetify.framework.theme.themes.dark,
    {
      primary: themeColor.lighten3,
      secondary: themeColor.lighten1,
      accent: themeColor.lighten3,
      [colorMenuTileBackground]: themeColor.darken4,
      [colorMainBackground]: colors.shades.black,
    }
  );
}

function updateFromPartial<T>(obj: T, update: Partial<T>): T {
  return { ...obj, ...update };
}

export function toggleColor(): void {
  let colorIndex = colorList.indexOf(readThemeColorSetting()) + 1;

  if (colorIndex >= colorList.length) {
    colorIndex = 0;
  }
  console.log(colorIndex);

  localStorage.setItem(storageKeyMainColor, colorIndex.toString());
  applyThemeColor(colorList[colorIndex]);
}

export function pickColor(color: Color) {
  const colorIndex = colorList.indexOf(color);

  localStorage.setItem(storageKeyMainColor, colorIndex.toString());
  applyThemeColor(colorList[colorIndex]);
}
