<template>
  <v-card class="pt-6 mt-auto" color="transparent" flat max-width="750">
    <decisionHeaderComponent :value="allocation" />
    <approvalFirstStageComponent
      :value="allocation"
      v-if="viewStage"
      @load="setLoading"
    />
    <approvalSecondStageComponent
      :value="allocation"
      v-if="acceptedStage"
      @load="setLoading"
    />
  </v-card>
</template>

<script lang="ts">
import { DefaultVueType } from "@/model";
import spdecisionModule from "@/store/modules/spdecision-module";
import { Allocation, EnumTaskStage } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import ApprovalFirstStageComponent from "./approvalfirststage-component.vue";
import ApprovalSecondStageComponent from "./approvalsecondstage-component.vue";
import DecisionHeaderComponent from "./decisionheader-component.vue";

type DataType = { taskstage: typeof EnumTaskStage | null };
type ComputedType = {
  allocation: Allocation;
  stage: EnumTaskStage;
  viewStage: boolean;
  acceptedStage: boolean;
};
type MethodType = { setLoading(val: boolean): void };
type PropType = DefaultVueType;

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "DecisionComponent",
  data: () => ({
    taskstage: null,
  }),
  components: {
    decisionHeaderComponent: DecisionHeaderComponent,
    approvalFirstStageComponent: ApprovalFirstStageComponent,
    approvalSecondStageComponent: ApprovalSecondStageComponent,
  },
  watch: {
    stage(val) {
      if (val) {
        this.setLoading(false);
      }
    },
  },
  computed: {
    allocation() {
      return spdecisionModule.allocation;
    },
    stage() {
      return this.allocation?.task?.stage;
    },
    viewStage() {
      return this.stage === EnumTaskStage.viewed;
    },
    acceptedStage() {
      const morethanAccepted = this.stage >= EnumTaskStage.accepted;
      const lessthanCompleted = this.stage <= EnumTaskStage.done;
      return morethanAccepted && lessthanCompleted;
    },
  },
  methods: {
    setLoading(val) {
      this.$emit("load", val);
    },
  },
});
</script>

<style></style>
