<template>
  <v-card>
    <v-form @submit="onSubmit" lazy-validation v-model="valid">
      <v-card flat class="pa-4 mt-6">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                filled
                outlined
                flat
                :label="$t('object.person.firstName')"
                v-model="formItems.firstName"
                :disabled="disabled"
                :rules="formRules.nameRules"
                required
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                outlined
                filled
                :label="$t('object.person.lastName')"
                :rules="formRules.nameRules"
                :disabled="disabled"
                required
                v-model="formItems.lastName"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                outlined
                filled
                :rules="formRules.emailRules"
                :disabled="disabled"
                :label="$t('object.person.email')"
                required
                v-model="formItems.email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                filled
                required
                hide-spin-buttons
                :disabled="disabled"
                :label="$t('object.person.phoneNo')"
                :placeholder="$t('object.person.phoneNoPlaceHolder')"
                :rules="formRules.phoneNumberRules"
                v-model="formItems.phoneNumber"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                outlined
                filled
                :label="$t('object.person.address')"
                required
                v-model="formItems.address"
                :disabled="disabled"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                outlined
                filled
                :label="$t('object.person.postalCode')"
                :disabled="disabled"
                required
                v-model="formItems.postalCode"
              />
            </v-col>
          </v-row>
          <v-row de></v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                filled
                :label="$t('object.person.city')"
                required
                :disabled="disabled"
                v-model="formItems.city"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                filled
                :label="$t('object.serviceProvider.percentage')"
                :hint="percentageHint"
                :disabled="disabled"
                persistent-hint
                v-if="isServiceProvider"
                v-model="formItems.percentage"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8" v-if="isServiceProvider">
              <v-text-field
                outlined
                filled
                :disabled="disabled"
                label="IBAN"
                :required="isServiceProvider"
                v-model="formItems.iban"
              />
            </v-col>
            <v-col cols="12" md="4" v-if="isServiceProvider">
              <v-text-field
                outlined
                filled
                label="BIC"
                :required="isServiceProvider"
                :disabled="disabled"
                v-model="formItems.bic"
              />
            </v-col>
            <v-col cols="12" md="4" v-if="isServiceProvider">
              <v-text-field
                outlined
                filled
                :label="$t('object.serviceProvider.bankName')"
                :required="isServiceProvider"
                :disabled="disabled"
                v-model="formItems.bankName"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-row dense align-content="end" justify="start" class="pa-4">
            <v-col cols="12" md="2" align-self="start">
              <v-btn
                color="primary"
                class="rounded-lg"
                outlined
                block
                @click="onClear"
                width="120"
              >
                {{ $t("site.btn.cancel") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                color="primary"
                block
                type="submit"
                class="rounded-lg"
                :disabled="disabledSubmitBtn"
                width="120"
              >
                {{ $t("site.btn.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { FormRules, formRules } from "@/helpers/component-helper";
import { LocalPerson } from "@/model";
import { i18n } from "@/plugins/i18n";
import Vue from "vue";
import { PropValidator } from "vue/types/options";

type PropType = {
  value: LocalPerson;
  isServiceProvider: boolean;
  headerTitle: string;
};
type DataType = { disabled: boolean; valid: boolean };
type MethodType = { onClear(): void; onSubmit(): void; toggleEditMode(): void };
type ComputeType = {
  formItems: LocalPerson;
  formRules: FormRules;
  disabledSubmitBtn: boolean;
  percentageHint: string;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "ServiceProviderFormComponent",
  components: {},
  data: () => ({
    disabled: false,
    valid: false,
  }),
  computed: {
    formItems: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
    formRules() {
      return formRules;
    },
    disabledSubmitBtn() {
      return !this.valid;
    },
    percentageHint() {
      if (this.formItems.percentage) {
        return i18n
          .t("object.person.sharingHint", [
            this.formItems.firstName ?? "Worker",
            this.formItems.percentage,
            100 - Number(this.formItems.percentage),
          ])
          .toString();
      }
      return i18n.t("object.person.defaultSharingHint").toString();
    },
  },
  props: {
    // eslint-disable-next-line prettier/prettier
    value: { type: Object } as PropValidator< LocalPerson >,
    isServiceProvider: { type: Boolean },
    headerTitle: { type: String },
  },
  watch: {
    formItems(val: LocalPerson) {
      this.disabled = false;
      if (val.id) {
        this.disabled = true;
      }
    },
  },
  methods: {
    onClear() {
      this.formItems = {} as LocalPerson;
      this.$emit("onClose");
    },
    onSubmit() {
      this.$emit("onSubmit", this.formItems);
    },
    toggleEditMode() {
      this.disabled = !this.disabled;
    },
  },
});
</script>
