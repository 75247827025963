<template>
  <serviceProviderProfileComponent />
</template>

<script lang="ts">
import ServiceProviderProfileComponent from "@/components/admin/serviceproviderprofile-component.vue";
import { catHelper } from "@/helpers/logging";
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import Vue from "vue";

export default Vue.extend({
  name: "ServiceProviderProfileView",
  components: {
    serviceProviderProfileComponent: ServiceProviderProfileComponent,
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
  },
  watch: {
    "$route.params.id": function () {
      this.getServiceProviderData();
    },
  },
  mounted() {
    this.getServiceProviderData();
  },
  methods: {
    getServiceProviderData() {
      this.loading = true;
      const id = parseInt(this.$route.params.id);
      if (!id || typeof id !== "number") {
        this.$router.back();
        this.loading = false;
        catHelper.info("unable to visit ServProvider" + id);
        return;
      }
      serviceproviderModule
        .retrieveServiceProvider(id)
        .then((val) => {
          if (!val) {
            this.$router.back();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
</script>

<style></style>
