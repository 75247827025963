<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        style="font-weight: 600; text-align: center; align-self: center"
        class="pa-0 centered-input"
        background-color="transparent"
        rows="1"
        chips
        flat
        :label="label"
        :prepend-inner-icon="range ? 'mdi-chevron-left' : null"
        :append-icon="range ? 'mdi-chevron-right' : null"
        :placeholder="placeholder"
        readonly
        :hide-details="hideDetails"
        :dense="dense"
        :filled="filled"
        :solo="solo"
        :clearable="textClearable"
        clear-icon="mdi-close-circle-outline"
        :outlined="outlined"
        v-bind="attrs"
        v-on="on"
        @click:clear="clearMessage"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      no-title
      scrollable
      :range="range"
      :min="min"
      :max="max"
    >
      <v-btn color="primary" text @click="onClear">Clear</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="submit"> Ok </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import Vue from "vue";

type ValueType = string | Date | string[] | null;
type PropType = {
  min: string;
  max: string;
  value: ValueType;
  solo: boolean;
  label: string;
  outlined: boolean;
  dense: boolean;
  filled: boolean;
  range: boolean;
  dividerText: string;
  clearable: boolean;
  textClearable: boolean;
  placeholder: string;
  hideDetails: boolean;
};
type MethodType = { clearMessage(): void; submit(): void; onClear(): void };
type ComputeType = {
  selectedDate: ValueType;
  formattedDate: ValueType;
};
type DataType = { menu: boolean };

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "DateRangeComponent",
  data: () => ({
    menu: false,
  }),
  props: {
    min: { type: String },
    max: { type: String },
    value: { type: Array || String || Date },
    label: { type: String },
    range: { type: Boolean },
    filled: { type: Boolean },
    solo: { type: Boolean },
    dense: { type: Boolean },
    dividerText: { type: String },
    clearable: { type: Boolean },
    textClearable: { type: Boolean },
    outlined: { type: Boolean },
    placeholder: { type: String },
    hideDetails: { type: Boolean },
  },
  watch: {},
  computed: {
    selectedDate: {
      get() {
        return this.value ?? null;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formattedDate() {
      const rangeAndDivider = !!this.range && !!this.dividerText;
      if (rangeAndDivider && Array.isArray(this.selectedDate)) {
        return this.selectedDate.join(` ${this.dividerText} `);
      }
      return this.selectedDate ?? null;
    },
  },
  methods: {
    clearMessage() {
      this.selectedDate = "";
    },
    submit() {
      this.$emit("change", this.selectedDate);
      this.menu = false;
    },
    onClear() {
      this.selectedDate = null;
      this.formattedDate = "All";
      this.menu = false;
    },
  },
});
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
