<template>
  <span class="px-10">
    <v-tabs
      fixed-tabs
      background-color="primary"
      dark
      v-model="tabs"
      centered
      class="mb-4 elevation-6 rounded"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab>{{ $t("object.statistics.tabs.noOfTasks") }}</v-tab>
      <v-tab>{{ $t("object.statistics.tabs.earnings") }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs" class="elevation-6 rounded">
      <v-tab-item class="grey lighten-3">
        <countStatisticsComponent v-if="tabs == 0" />
      </v-tab-item>
      <v-tab-item>
        <amountStatisticsComponent v-if="tabs == 1" />
      </v-tab-item>
    </v-tabs-items>
    <statisticsTable class="mt-4 elevation-6" />
  </span>
</template>

<script lang="ts">
import Vue from "vue";

import { colorMainBackground } from "../../plugins/vuetify";
import AmountStatisticsComponent from "../subcomponent/amountstatistics-component.vue";
import CountStatisticsComponent from "../subcomponent/countstatistics-component.vue";

const StatisticsTable = () =>
  import("../subcomponent/statisticstable-component.vue");

export default Vue.extend({
  name: "StatisticsComponent",
  components: {
    amountStatisticsComponent: AmountStatisticsComponent,
    statisticsTable: StatisticsTable,
    countStatisticsComponent: CountStatisticsComponent,
  },
  data: () => ({
    tabs: 0,
  }),
  computed: {
    backgroundColor() {
      return colorMainBackground;
    },
  },
});
</script>

<style></style>
