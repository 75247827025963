<template>
  <v-card flat tile color="transparent">
    <previewHeaderComponent
      class="mb-2"
      @back="onClose"
      :title="$t('object.task.newTask')"
    />
    <taskFormComponent
      v-model="task"
      @onClose="onClose"
      @onSubmit="onSubmit"
      :today="today"
    />
  </v-card>
</template>

<script lang="ts">
import { Task } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import PreviewHeaderComponent from "../previewheader-component.vue";
import TaskFormComponent from "./taskform-component.vue";

type DataType = Record<string, string>;
type MethodType = { onClose(): void; onSubmit(): void };
type ComputedType = { task: Task };
type PropType = { value: Task; today: string };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "NewTaskComponent",
  computed: {
    task: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  components: {
    taskFormComponent: TaskFormComponent,
    previewHeaderComponent: PreviewHeaderComponent,
  },
  props: {
    value: { type: Object },
    today: { type: String },
  },
  mounted() {
    this.task = new Task();
  },
  methods: {
    onClose() {
      this.$emit("onClose");
    },
    onSubmit() {
      this.$emit("onSubmit");
    },
  },
});
</script>

<style></style>
