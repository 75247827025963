import {
  Identification,
  STATISTICS_ROUTE,
  StatisticsDesc,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

const url = STATISTICS_ROUTE;
export class StatisticsService extends BaseService<
  Identification & StatisticsDesc
> {
  constructor() {
    super(url);
  }
}
