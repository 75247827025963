<template>
  <v-container class="pa-0">
    <v-row align="start">
      <v-col align-self="start">
        <span class="text-h4 font-weight-bold float-left">
          {{ title }}
        </span>
      </v-col>
      <v-col>
        <v-container class="float-right">
          <v-btn
            class="float-right rounded-lg"
            color="primary"
            @click="createNew"
          >
            <v-icon left>mdi-file-plus-outline</v-icon>
            {{ $t("site.btn.createNew") }}
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <v-col v-for="(n, i) in invoiceTypes" cols="3" :key="i">
        <v-card class="fill-width pa-4" rounded="lg" hover>
          <v-row dense>
            <v-col
              class="text-h2 pa-0"
              cols="4"
              align-self="center"
              justify="end"
            >
              <v-avatar
                fab
                style="justify-self: end"
                size="72"
                :color="n.backgroundColor"
              >
                <v-icon large :color="n.color">{{ n.icon }}</v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="6" align-self="start" class="">
              <v-list-item
                dense
                class="text-h5 font-weight-bold float-start pa-0"
                two-line
              >
                <v-list-item-content class="justify-start float-start">
                  <v-list-item-subtitle class="text-start">
                    <strong class="text-h5 font-weight-bold">
                      {{ n.value }}
                    </strong>
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-start align-start">
                    <strong class="text-h5 font-weight-bold">
                      {{ n.count }}
                    </strong>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { TypeStageFormatter } from "@/model";
import { EnumInvoiceStage } from "@taskmanagement/taskapp-model/dist";
import Vue from "vue";
import { PropValidator } from "vue/types/options";

type PropType = Record<string, string>;
type MethodType = { createNew(): void };
type DataType = Record<string, string>;
type ComputeType = Record<string, string>;

export default Vue.extend({
  name: "InvoiceHeaderComponent",
  props: {
    title: { type: String },
    invoiceTypes: { type: Array } as PropValidator<
      TypeStageFormatter<EnumInvoiceStage>[]
    >,
  },
  methods: {
    createNew() {
      this.$emit("new");
    },
  },
});
</script>
