<template>
  <v-container>
    <span>
      <small> {{ $t("object.statistics.donut.header") }} </small>

      <v-spacer></v-spacer>
      <v-divider vertical inset />
      <span dense class="primary--text pl-2" v-if="totalAllocations">
        {{ $t("object.statistics.donut.totalAllocations", [totalAllocations]) }}
      </span>
    </span>
    <v-divider></v-divider>

    <v-tabs color="secondary" left>
      <v-tab> {{ $t("object.allocation.stagesName") }} </v-tab>
      <v-tab> {{ $t("object.allocation.decisionName") }} </v-tab>
      <v-tab-item>
        <doughnutChartComponent
          :value="totalStages"
          :labelValues="stagesLabels"
        />
      </v-tab-item>
      <v-tab-item>
        <doughnutChartComponent
          :value="totalStatus"
          :labelValues="statusLabels"
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script lang="ts">
import statisticsModule from "../../store/modules/statistics-module";
import Vue from "vue";
import DoughnutChartComponent from "./doughnutchart-component.vue";
import { stagesLabel, statusLabel } from "../../helpers/statistics-helper";

export default Vue.extend({
  name: "TotalDonutComponent",
  components: {
    doughnutChartComponent: DoughnutChartComponent,
  },
  data: () => ({
    val: 0,
  }),
  computed: {
    totalAllocations() {
      const stages = Object.values(statisticsModule.totals?.stages ?? {});
      const status = Object.values(statisticsModule.totals?.status ?? {});
      const totalStatus = status.reduce((acc, val) => acc + val, 0);
      const totalStages = stages.reduce((acc, val) => acc + val, 0);
      const returnVal = totalStages === totalStatus ? totalStages : null;
      return returnVal > 0 ? returnVal : null;
    },
    totalStages() {
      return statisticsModule.totals?.stages ?? {};
    },
    totalStatus() {
      return statisticsModule.totals?.status ?? {};
    },
    statusLabels() {
      return statusLabel;
    },
    stagesLabels() {
      return stagesLabel;
    },
  },
});
</script>

<style></style>
