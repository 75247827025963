<template>
  <v-container class="pt-6">
    <v-slide-y-transition leave-absolute v-if="!loading">
      <v-row>
        <v-col cols="12" :md="!showOthers ? 12 : 8" class="mb-12">
          <v-fade-transition>
            <requestResponseComponent
              v-if="!isApprove"
              :title="requestDialogTitle"
              :subtitle="requestDialogSubtitle"
              :requestType="requestType"
            />
          </v-fade-transition>

          <v-slide-y-transition v-if="isApproval">
            <decisionComponent @load="toggleLoading" />
          </v-slide-y-transition>
        </v-col>
        <v-col cols="12" :md="!showOthers ? 12 : 4" v-if="showOthers">
          <v-slide-y-transition>
            <openTasksComponent
              class="mt-20"
              transition="scale-transition"
              @approval="isApprove = true"
              :allocations="pendings"
            />
          </v-slide-y-transition>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <span v-if="loading">
      <decisionLoadingComponent />
    </span>

    <v-btn
      absolute
      fixed
      large
      fab
      bottom
      right
      class="mb-12"
      color="primary"
      @click="showActiveTasks"
    >
      <v-icon v-if="showOthers">mdi-table-eye-off</v-icon>
      <v-icon v-else>mdi-table-eye</v-icon>
    </v-btn>
  </v-container>
</template>

<script lang="ts">
import { loadParams } from "@/helpers/decision-helpers";
import { TypeRequest } from "@/model";
import { i18n } from "@/plugins/i18n";
import spdecisionModule from "@/store/modules/spdecision-module";
import {
  Allocation,
  EnumStatus,
  EnumTaskStage,
} from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { POSITION } from "vue-toastification";

import DecisionComponent from "../subcomponent/decision/decision-component.vue";
import DecisionLoadingComponent from "../subcomponent/decision/decisionloading-component.vue";
import OpenTasksComponent from "../subcomponent/opentasks-component.vue";
import RequestResponseComponent from "../subcomponent/requestresponse-component.vue";

export default Vue.extend({
  name: "ApprovalComponent",
  components: {
    requestResponseComponent: RequestResponseComponent,
    openTasksComponent: OpenTasksComponent,
    decisionComponent: DecisionComponent,
    decisionLoadingComponent: DecisionLoadingComponent,
  },
  data: () => ({
    ticket: null as string | null,
    id: null as string | null,
    priceDialog: false,
    isApprove: null as null | boolean,
    showRequestDialog: false,
    requestType: "" as TypeRequest,
    requestDialogTitle: undefined as string | undefined,
    requestDialogSubtitle: undefined as string | undefined,
    hidden: true,
    showOthers: false,
    loading: false,
  }),
  watch: {
    isApproval(val) {
      if (val === false) {
        this.requestDialogTitle = `Order Not Found`;
        this.requestDialogSubtitle =
          "The Order you are trying to view is either timedOut or already completed";
      }
    },
    stage(val) {
      if (val) {
        this.toggleLoading(false);
      }
    },
  },
  computed: {
    isApproval() {
      const approval = spdecisionModule.allocation;
      const decision =
        approval.decision === EnumStatus.accepted || !approval.decision;
      const approvalStage =
        approval.stage > EnumTaskStage.declined &&
        approval.stage < EnumTaskStage.done;

      return approvalStage && decision;
    },
    allocation() {
      return spdecisionModule.allocation as Allocation;
    },
    pendings() {
      return spdecisionModule.pendings as Allocation[];
    },
    breakPoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    stage() {
      return spdecisionModule.allocation?.task?.stage;
    },
  },
  mounted() {
    this.queryParams();
  },
  methods: {
    toggleLoading(val: boolean) {
      this.loading = val;
    },
    queryParams() {
      this.loading = true;
      this.ticket = this.$route.query.ticketNumber as string;
      this.id = this.$route.query.id as string;
      loadParams(this.ticket, this.id, this.badRequest).finally(() => {
        this.loading = false;
        this.isApprove = this.isApproval;
      });
    },
    decline() {
      this.completeRequest();
    },
    completeRequest() {
      this.requestType = "success";
      this.requestDialogTitle = i18n.t("site.response.thankyou").toString();
      this.requestDialogSubtitle = i18n
        .t("site.response.thanksSubtitle")
        .toString();
      this.isApprove = false;
    },
    badRequest() {
      this.requestType = "badRequest";
      this.showRequestDialog = true;
    },
    showActiveTasks() {
      if (this.pendings.length > 0) {
        this.showOthers = !this.showOthers;
        if (this.breakPoint && this.showOthers) {
          this.$vuetify.goTo(document.body.scrollHeight);
        }
      } else {
        this.$toast.success("You have No open tasks", {
          position: POSITION.BOTTOM_RIGHT,
          closeOnClick: true,
        });
      }
    },
    setApproval() {
      this.isApprove = true;
    },
  },
});
</script>

<style></style>
