import {
  SERVICEPROVIDER_ALL_ROUTE,
  SERVICEPROVIDER_ROUTE,
  ServiceProvider,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

const url = SERVICEPROVIDER_ROUTE;
export class ServiceProviderService extends BaseService<ServiceProvider> {
  constructor() {
    super(url);
  }

  getAllWithInActive() {
    return this.axios
      .get(SERVICEPROVIDER_ALL_ROUTE)
      .then((res) => res.data as ServiceProvider);
  }
}
