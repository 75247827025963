<template>
  <span>
    <dashboardComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import DashboardComponent from "../components/dashboard-component.vue";
import allocationModule from "../store/modules/allocation-module";
import serviceproviderModule from "../store/modules/serviceprovider-module";

import authModule from "@/store/modules/auth-module";
import taskModule from "../store/modules/task-module";

export default Vue.extend({
  name: "DashboardView",
  data: () => ({}),
  computed: {
    loadingMsg() {
      return this.$i18n.t("site.loader.dashboard");
    },
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
    minDate() {
      return this.$route.query.minDate as string | undefined;
    },
    maxDate() {
      return this.$route.query.maxDate as string | undefined;
    },
  },
  components: {
    dashboardComponent: DashboardComponent,
  },
  mounted() {
    this.loadPromises();
  },
  methods: {
    loadPromises() {
      this.loading = true;
      taskModule
        .retriveTaskList({ minDate: this.minDate, maxDate: this.maxDate })
        .then(() => allocationModule.retrieveList())
        .then(() => serviceproviderModule.retrieveServiceProviderList())
        .finally(() => {
          this.loading = false;
          this.periodicLoader();
        });
    },
    periodicLoader() {
      const interval = setInterval(() => {
        if (authModule.isLoggedIn) {
          taskModule.retriveTaskList({
            minDate: this.minDate,
            maxDate: this.maxDate,
          });
        } else {
          clearInterval(interval);
        }
      }, 10 * 60000);
    },
  },
});
</script>

<style></style>
