<template>
  <span>
    <previewHeaderComponent
      class="mb-2"
      @back="onClose"
      :title="$t('object.serviceProvider.newHeaderTxt')"
    />
    <serviceProviderFormComponent
      class="my-2"
      v-model="serviceProvider"
      @onSubmit="onSave"
      @onClose="onClose"
      isServiceProvider
    />
  </span>
</template>

<script lang="ts">
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import { ServiceProvider } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import PreviewHeaderComponent from "../previewheader-component.vue";
import ServiceProviderFormComponent from "./serviceproviderform-component.vue";

export default Vue.extend({
  name: "NewServProviderComponent",
  data: () => ({
    serviceProvider: serviceproviderModule.serviceProvider,
  }),
  components: {
    serviceProviderFormComponent: ServiceProviderFormComponent,
    previewHeaderComponent: PreviewHeaderComponent,
  },
  methods: {
    onSave(item: ServiceProvider) {
      console.log(item);
      this.$emit("submit", item);
    },
    onClose() {
      console.log("close");
    },
  },
});
</script>

<style></style>
