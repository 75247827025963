<template>
  <v-card color="transparent" flat class="mb-4 px-1">
    <v-row dense class="py-2">
      <span class="text-h4 font-weight-bold">
        {{ title }}
      </span>

      <v-spacer />

      <v-btn color="primary" @click="createNew" class="rounded-lg">
        <v-icon left>mdi-file-plus-outline</v-icon>
        {{ $t("site.btn.createNew") }}
      </v-btn>
    </v-row>

    <v-row dense>
      <span>
        <dateRangeComponent
          solo
          range
          dividerText="-"
          hideDetails
          v-model="dateRange"
          @change="dateUpdate"
        />
      </span>

      <v-spacer />

      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        solo
        clearable
        class="rounded-lg pa-0"
        flat
        :placeholder="$t('object.task.header.search')"
        full-width
        dense
        @input="updateSearch"
        hide-details
      >
        <template v-slot:append v-if="filter">
          <v-btn
            text
            style="text-transform: none; font-weight: 600"
            class="ma-0"
          >
            <v-icon left>mdi-filter-outline</v-icon>
            Filter
          </v-btn>
        </template>
      </v-text-field>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import moment from "moment";
import Vue from "vue";
import DateRangeComponent from "./daterange-component.vue";

export default Vue.extend({
  name: "TableHeaderComponent",
  components: {
    dateRangeComponent: DateRangeComponent,
  },
  data: () => ({
    search: null,
    dateRange: [
      moment().subtract(1, "months").format("DD-MM-YYYY"),
      moment().format("DD-MM-YYYY"),
    ],
  }),
  props: {
    title: { type: String },
    filter: { type: Boolean },
  },
  mounted() {
    this.$emit("ondate", this.dateRange);
  },
  methods: {
    createNew() {
      this.$emit("createNew");
    },
    dateUpdate() {
      this.$emit("ondate", this.dateRange);
    },
    updateSearch() {
      this.$emit("onSearch", this.search);
    },
  },
});
</script>

<style></style>
