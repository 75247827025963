import {
  ServiceProvider,
  TotalBaseStatistics,
} from "@taskmanagement/taskapp-model";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";
import { StatisticsService } from "../../services/statistics-service";

@Module({
  name: "statistics",
  store,
  dynamic: true,
})
class StatisticsModule extends VuexModule {
  serviceProvider: TotalBaseStatistics<ServiceProvider> = {};
  totals: TotalBaseStatistics<ServiceProvider>["total"] = {};
  items: TotalBaseStatistics<ServiceProvider>["items"] = [];

  @Action({ commit: "getServiceProvider" })
  retrieveServiceProviderStat() {
    return new StatisticsService().getAll();
  }

  @Mutation
  getServiceProvider(serviceProvider: TotalBaseStatistics<ServiceProvider>) {
    this.serviceProvider = serviceProvider;
    this.totals = serviceProvider.total;
    this.items = serviceProvider.items;
  }
}

export default getModule(StatisticsModule);
