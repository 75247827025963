<template>
  <v-chip
    label
    :outlined="outlined"
    :ripple="false"
    :width="width"
    :small="small"
    color="transparent"
    class="px-0 justify-center align-center"
    :text-color="color"
    link
  >
    <v-icon :color="color" small class="pr-1" v-if="!!color">
      mdi-circle
    </v-icon>
    <v-icon left small>{{ icon }}</v-icon>
    <small style="font-weight: 600" :color="color">
      {{ text }}
    </small>
  </v-chip>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "StatusDisplayComponent",
  props: {
    color: { type: String },
    textColor: { type: String, default: "white" },
    width: { type: Number, default: 110 },
    outlined: { type: Boolean },
    text: { type: String },
    small: { type: Boolean },
    icon: { type: String },
  },
});
</script>

<style></style>
