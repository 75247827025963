<template>
  <v-card flat color="transparent" class="pa-0">
    <v-container class="align-center justify-center my-auto px-0">
      <v-card-title class="py-0 justify-center">
        <strong> {{ $t("object.task.taskInfo") }} </strong>
      </v-card-title>
      <v-divider class="py-2" />

      <v-row dense v-for="(client, i) in clientInformation" :key="i">
        <v-col cols="6" align="start">
          {{ client.title }}
        </v-col>
        <v-col cols="6" align="end">
          <strong> {{ client.value }} </strong>
        </v-col>
      </v-row>

      <v-card-subtitle class="error--text mt-auto">
        {{ $t("object.misc.detailsRestrict") }}
      </v-card-subtitle>

      <v-card-actions class="mt-10 pt-10">
        <v-row align-content="center" justify="start">
          <v-col cols="12" md="2" align-self="start">
            <v-btn block large @click="accept" color="primary">
              {{ $t("site.btn.accept") }}
            </v-btn>
          </v-col>

          <v-col cols="12" md="2">
            <v-btn block large outlined @click="decline" color="error">
              {{ $t("site.btn.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { tastTimeDisplay } from "@/helpers/component-helper";
import { setAcceptTask, setDeclineTask } from "@/helpers/decision-helpers";
import { DefaultVueType, InformationType } from "@/model";
import { Allocation } from "@taskmanagement/taskapp-model";

import Vue from "vue";

type PropType = { value: Allocation };
type MethodType = {
  accept(): void;
  decline(): void;
  setLoading(val: boolean): void;
};
type DataType = DefaultVueType;
type ComputedType = { clientInformation: InformationType[] };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "ApprovalFirstStageComponent",

  props: {
    value: { type: Object },
  },
  computed: {
    clientInformation() {
      const task = this.value?.task;
      return [
        { title: this.$i18n.t("object.task.title"), value: task?.title },
        {
          title: this.$i18n.t("object.task.description"),
          value: task?.description,
        },
        {
          title: this.$i18n.t("object.task.appTime"),
          value: tastTimeDisplay(task, this.$route) as string,
        },
        {
          title: this.$i18n.t("object.person.postalCode"),
          value: task.postalCode,
        },
        {
          title: this.$i18n.t("object.person.city"),
          value: task.city,
        },
      ];
    },
  },
  methods: {
    accept() {
      const alloc = this.value;
      this.setLoading(true);
      setAcceptTask(alloc).finally(() => this.setLoading(false));
    },
    decline() {
      this.setLoading(true);
      setDeclineTask(this.value).finally(() => this.setLoading(false));
    },
    setLoading(val) {
      this.$emit("load", val);
    },
  },
});
</script>
