<template>
  <v-row>
    <v-col cols="12" md="8">
      <barChartComponent
        :series="series"
        :labelList="labelList"
        money
        :yaxisLabel="yaxisLabel"
      />
    </v-col>
    <v-col cols="12" md="4">
      <doughnutChartComponent
        money
        :value="donutSeries"
        :labelValues="stagesLabels"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import statisticsModule from "../../store/modules/statistics-module";
import Vue from "vue";
import BarChartComponent from "./barchart-component.vue";
import DoughnutChartComponent from "./doughnutchart-component.vue";
import {
  getBarChartPriceSeries,
  getSPListFromStatistics,
  stagesLabel,
} from "../../helpers/statistics-helper";

export default Vue.extend({
  name: "AmountStatisticsComponent",
  components: {
    barChartComponent: BarChartComponent,
    doughnutChartComponent: DoughnutChartComponent,
  },
  data: () => ({}),
  computed: {
    series() {
      return getBarChartPriceSeries(statisticsModule.items);
    },
    labelList() {
      return getSPListFromStatistics(statisticsModule?.items);
    },
    yaxisLabel() {
      return "Total Amounts";
    },
    donutSeries() {
      return statisticsModule.totals?.stages ?? {};
    },
    stagesLabels() {
      return stagesLabel.filter((x) => x.text != "Non");
    },
  },
});
</script>

<style></style>
