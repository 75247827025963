import {
  Allocation,
  EnumStatus,
  EnumTaskStage,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import spdecisionModule from "../store/modules/spdecision-module";

type QueryParam = { ticketNumber: string; id: string };
export function getAllocation(obj: QueryParam) {
  return spdecisionModule.retrieveAllocation(obj);
}

export function loadParams(
  ticketNumber: string,
  id: string,
  badRequest: () => void
) {
  return getAllocation({ ticketNumber, id })
    .then((val) => {
      if (!val) {
        badRequest();
      }
    })
    .catch((err) => {
      if (err) {
        badRequest();
      }
    });
}

export function setAcceptIdentity(allocation: Allocation) {
  const allocate = allocation;
  allocate.stage = EnumTaskStage.viewed;
  return spdecisionModule.updateAction(allocate);
}

export function setDeclineIdentity(
  allocation: Allocation,
  triggerFinish: () => void
) {
  allocation.stage = EnumTaskStage.declined;
  allocation.decision = EnumStatus.badRequest;
  return spdecisionModule.updateAction(allocation).finally(triggerFinish);
}

export function setAcceptTask(allocation: Allocation) {
  allocation.decision = EnumStatus.accepted;
  allocation.stage = EnumTaskStage.accepted;
  return spdecisionModule.updateAction(allocation);
}

export function setDeclineTask(
  allocation: Allocation,
  triggerFinish?: () => void
) {
  allocation.decision = EnumStatus.declined;
  return spdecisionModule.updateAction(allocation).finally(triggerFinish);
}

export function finishTask(allocation: Allocation, triggerFinish?: () => void) {
  allocation.stage = EnumTaskStage.done;
  allocation.task.stage = EnumTaskStage.done;
  return spdecisionModule.updateAction(allocation).then(triggerFinish);
}

export function unableToFinish(
  allocation: Allocation,
  triggerFinish?: () => void
) {
  allocation.decision = EnumStatus.declined;
  return spdecisionModule.updateAction(allocation).finally(triggerFinish);
}

export function countDownTimer(refTime?: string): () => string {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isRunning = false;
  let time = 0;
  let timer: NodeJS.Timeout | null = null;

  const timeAllowed = 60; // For example, set to 60 minutes
  const allowedTime = moment(refTime).add(timeAllowed, "minutes");

  function start() {
    const now = moment();
    const diffSeconds = allowedTime.diff(now, "seconds");

    if (diffSeconds <= 0) {
      time = 0;
      isRunning = false;
      return;
    }

    time = diffSeconds;
    isRunning = true;

    if (!timer) {
      timer = setInterval(() => {
        if (time > 0) {
          time--;
        } else {
          clearInterval(timer as NodeJS.Timeout);
          reset();
        }
      }, 1000);
    }
  }

  function stop() {
    isRunning = false;
    if (timer) {
      clearInterval(timer as NodeJS.Timeout);
      timer = null;
    }
  }

  function reset() {
    stop();
    time = 0;
  }

  start();

  return () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
}
