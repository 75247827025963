/* eslint-disable prettier/prettier */
import { API_URL } from "@/model/config";
import {
  authTokenName
} from "@taskmanagement/taskapp-model";
import axios from "axios";
import { getCookies } from "./cookies-helper";
import { logoutUser } from "./router-helpers";

const localAxios = axios.create({
  baseURL: API_URL,
});

localAxios.interceptors.request.use(
  (config) => {
    const token = getCookies(authTokenName);
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

localAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 400) {
      logoutUser();
    }
    return Promise.reject(error);
  }
);

export default localAxios;
