<template>
  <!-- <v-container> -->
  <v-row>
    <v-col cols="12" md="8">
      <!-- <v-card> -->
      <barChartComponent
        :series="series"
        :labelList="labelList"
        :subTitle="$t('object.statistics.barchart.count.subheader')"
        :title="$t('object.statistics.barchart.count.title')"
        :yaxisLabel="$t('object.statistics.tabs.noOfTasks')"
      />
      <!-- </v-card> -->
    </v-col>
    <v-col cols="12" md="4">
      <!-- <v-card> -->
      <totalDonutComponent />
      <!-- </v-card> -->
    </v-col>
  </v-row>
  <!-- <v-row>
      <v-col>
        <v-card>
          <statisticsTableComponent />
        </v-card>
      </v-col>
    </v-row> -->
  <!-- </v-container> -->
</template>

<script lang="ts">
import Vue from "vue";
import BarChartComponent from "./barchart-component.vue";
import TotalDonutComponent from "./totaldonut-component.vue";
import {
  getBarChartCountSeries,
  getSPListFromStatistics,
} from "../../helpers/statistics-helper";
import statisticsModule from "../../store/modules/statistics-module";

export default Vue.extend({
  name: "CountStatisticsComponent",
  components: {
    barChartComponent: BarChartComponent,
    totalDonutComponent: TotalDonutComponent,
  },
  computed: {
    series() {
      return getBarChartCountSeries(statisticsModule?.items);
    },
    labelList() {
      return getSPListFromStatistics(statisticsModule?.items);
    },
  },
});
</script>

<style></style>
