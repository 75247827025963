import router, { ROUTE_HOME, ROUTE_LOGIN } from "@/plugins/router";
import { LoginUser } from "@taskmanagement/taskapp-model";
import { RouteRecord } from "vue-router";
import { convertLocaleToUrlLocale, i18n } from "../plugins/i18n";
import authModule from "../store/modules/auth-module";

export function calcRouteValues(route: RouteRecord): boolean {
  if (!route.meta?.nav) return false;
  if (route.meta?.isHidden !== undefined) {
    return !route.meta?.isHidden;
  }

  if (route.meta?.doLogin !== undefined) {
    return !route.meta.doLogin || !authModule.isLoggedIn;
  }
  if (route.meta?.requireLogin !== undefined) {
    return !route.meta?.requireLogin || authModule.isLoggedIn;
  }
  if (route.parent) return calcRouteValues(route.parent);
  return false;
}

export function navigateToPathAfterAuth() {
  const currentPath = router.currentRoute.name;
  const locale = convertLocaleToUrlLocale(i18n.locale);
  const basePath = ROUTE_LOGIN;

  if (currentPath !== basePath) {
    const to = router.resolve({ params: { locale }, name: basePath });
    router.push(to.location);
  }
}

export function logoutUser() {
  return authModule.logoutUser().then(() => navigateToPathAfterAuth());
}
export function redirectToRedirectUrl() {
  const redirectUrl = router.currentRoute.query.redirect_url as string;
  if (redirectUrl) {
    router.push({ path: redirectUrl });
  } else {
    router.push({ name: ROUTE_HOME });
  }
}
export function loginUser(user: LoginUser) {
  return authModule.loginUser(user);
}
