<template>
  <span>
    <tableHeaderComponent
      :title="$t('object.task.listName')"
      @createNew="createNew"
      @onSearch="onSearch"
      @ondate="onFilter"
      class="mb-4"
    />

    <statusFilterComponent
      class="mt-10"
      :items="taskStatusDesc"
      @change="onFilterTabChange"
    />

    <v-card
      flat
      max-height="650"
      class="overflow-y-auto"
      outlined
      style="scrollbar-width: thin"
    >
      <v-data-table
        :dense="$vuetify.breakpoint.mdAndDown"
        :headers="headers"
        :items="tableItems"
        :search="search"
        calculate-widths
        fixed-header
        :loading="loading"
        :sort-by="'createdAt'"
        :sort-desc="true"
        single-select
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="transparent" small fab text @click="showMoreItem(item)">
            <v-icon color="primary"> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.alert`]="{ item }">
          <v-tooltip nudge-right="30" top color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-if="formatAlert(item)"
                color="transparent"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon :color="stageFormatter(item)?.color">mdi-alert</v-icon>
              </v-chip>
            </template>
            {{ stageFormatter(item)?.value }}
          </v-tooltip>
          <span>
            <v-card color="red"></v-card>
          </span>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span style="font-weight: 600">
            {{ timeFormatter(item) }}
          </span>
        </template>
        <template v-slot:[`item.requestedBy`]="{ item }">
          {{
            `${item?.requesterFirstName ?? ""} ${item?.requesterLastName ?? ""}`
          }}
        </template>
        <template v-slot:[`item.allocatedTo`]="{ item }">
          {{
            `${item?.allocatedTo.firstName ?? ""} ${
              item.allocatedTo.firstName ?? ""
            }`
          }}
        </template>
        <template v-slot:[`item.stage`]="{ item }">
          <statusDisplayComponent
            :color="stageFormatter(item)?.color"
            :text="stageFormatter(item)?.value"
          />
        </template>
      </v-data-table>
    </v-card>
  </span>
</template>

<script lang="ts">
import { QueryParams, TypeStageFormatter } from "@/model";
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import taskModule from "@/store/modules/task-module";
import {
  EnumBoolean,
  EnumStatus,
  EnumTaskStage,
  Person,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { DataTableHeader } from "vuetify";
import {
  headerEnumFormatter,
  headerPersonFormatter,
  headerTimeFormatter,
} from "../../../helpers/component-helper";
import StatusDisplayComponent from "../statusdisplay-component.vue";
import StatusFilterComponent from "../statusfilter-component.vue";
import TableHeaderComponent from "../tableheader-component.vue";

type DataType = {
  boolean: typeof EnumBoolean;
  search: string | null;
  selectedSP: ServiceProvider | null;
  selectedStatus: string | null;
};

type PropType = {
  items: Task[];
  loading: boolean;
  today: string;
  taskStatusDesc: TypeStageFormatter<EnumStatus>[];
  taskStageDesc: TypeStageFormatter<EnumTaskStage>[];
};

type MethodType = {
  onSearch(text: string | null): void;
  onFilterTabChange(item: EnumStatus): void;
  onFilter(params?: QueryParams<Task>): void;
  personFormatter(item: Person): string | undefined;
  formatBooleanIcon(
    value: EnumBoolean
  ): "mdi-check-circle-outline" | "mdi-cancel";
  formatAlert(val: Task): boolean;
  editItem(item: Task): void;
  showMoreItem(item: Task): void;
  statusFormatter(item: Task): TypeStageFormatter<EnumStatus> | null;
  createNew(): void;
  stageFormatter(item: Task): TypeStageFormatter<EnumTaskStage> | null;
};
type ComputeType = {
  headers: DataTableHeader[];
  headerFormatter: DataTableHeader[];
  timeFormatter: (item: Task) => string;
  serviceProviderList: ServiceProvider[];
  tableItems: Task[];
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "TaskTableComponent",
  data: () => ({
    boolean: EnumBoolean,
    search: null,
    selectedSP: null,
    selectedStatus: null,
  }),
  components: {
    statusDisplayComponent: StatusDisplayComponent,
    tableHeaderComponent: TableHeaderComponent,
    statusFilterComponent: StatusFilterComponent,
  },
  props: {
    items: { type: Array, default: new Array<Task>() } as PropValidator<Task[]>,
    taskStatusDesc: { type: Array },
    taskStageDesc: { type: Array },
    today: { type: String },
    loading: { type: Boolean },
  },
  filters: {
    lowercase(value: string) {
      return value.toLocaleLowerCase();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          value: "alert",
          align: "start",
          sortable: false,
          width: 50,
        } as DataTableHeader,
        {
          text: this.$i18n.t("object.task.header.ticketNo"),
          value: "ticketNumber",
          align: "start",
          class: "text-grey",
        } as DataTableHeader,
        {
          text: this.$i18n.t("object.task.title"),
          align: "start",
          value: "title",
        } as DataTableHeader,
        {
          text: this.$i18n.t("object.task.requestedBy"),
          align: "start",
          value: "requestedBy",
          sortable: false,
          formatter: headerPersonFormatter,
        } as DataTableHeader,
        {
          text: this.$i18n.t("object.task.allocatedTo"),
          align: "start",
          value: "allocatedTo",
          sortable: false,
        } as DataTableHeader,
        {
          text: this.$i18n.t("object.allocation.decision"),
          value: "stage",
          align: "start",
        } as DataTableHeader,
        {
          text: this.$i18n.t("object.task.header.createdOn"),
          value: "createdAt",
          formatter: headerTimeFormatter,
        } as DataTableHeader,
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
        } as DataTableHeader,
      ] as DataTableHeader[];
    },
    headerFormatter() {
      // eslint-disable-next-line no-prototype-builtins
      return this.headers.filter((x) => x.hasOwnProperty("formatter"));
    },
    timeFormatter() {
      return (item: Task) => headerTimeFormatter(item?.createdAt);
    },
    serviceProviderList() {
      return serviceproviderModule.serviceProviderList;
    },
    tableItems() {
      return this.items.filter(
        (x) => !this.selectedSP || x.allocatedTo.id === this.selectedSP?.id
      );
    },
  },
  methods: {
    onFilterTabChange(item: EnumStatus) {
      this.onFilter({ status: item });
    },
    onSearch(text) {
      this.search = text;
    },
    onFilter(date) {
      taskModule.retriveTaskList(date);
    },
    stageFormatter(item: Task) {
      return headerEnumFormatter(item?.stage, this.taskStageDesc);
    },
    statusFormatter(item: Task) {
      return headerEnumFormatter(item?.status, this.taskStatusDesc);
    },
    personFormatter(item: Person) {
      return headerPersonFormatter(item);
    },
    formatBooleanIcon(value: EnumBoolean) {
      if (value === EnumBoolean.true) {
        return "mdi-check-circle-outline";
      }
      return "mdi-cancel";
    },
    editItem(item: Task) {
      const val = Object.assign(new Task(), item);
      this.$emit("editItem", val);
    },
    showMoreItem(item: Task): void {
      const val = Object.assign({}, item);
      this.$emit("moreItem", val);
    },
    createNew() {
      this.$emit("createNew");
    },
    formatAlert(val) {
      const stage = val.stage;
      return stage < EnumTaskStage.created;
    },
  },
});
</script>

<style></style>
