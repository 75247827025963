<template>
  <span>
    <tableHeaderComponent @createNew="createNew" @onSearch="onSearch" />

    <v-data-table
      :dense="$vuetify.breakpoint.mdAndDown"
      :headers="headers"
      :items="items"
      :search="search"
      :server-items-length="items.length"
    >
      <template v-slot:[`item.picture`]="{ item }">
        <v-avatar>
          <v-img :src="item.img" v-if="item.img" />
          <v-icon v-else>mdi-account-circle</v-icon>
        </v-avatar>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <statusDisplayComponent
          :color="getSPStatus(item)?.color"
          :text="getSPStatus(item)?.text"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="transparent" small fab text @click="showMoreItem(item)">
          <v-icon color="primary"> mdi-dots-horizontal </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </span>
</template>

<script lang="ts">
import { ROUTE_WORKER_PROFILE } from "@/plugins/router";
import { EnumBoolean, ServiceProvider } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { DataTableHeader } from "vuetify";
import StatusDisplayComponent from "../statusdisplay-component.vue";
import TableHeaderComponent from "../tableheader-component.vue";

export default Vue.extend({
  name: "ServiceProviderTableComponent",
  data: () => ({
    search: null as string | null,
  }),
  components: {
    tableHeaderComponent: TableHeaderComponent,
    statusDisplayComponent: StatusDisplayComponent,
  },
  props: {
    items: { type: Array } as PropValidator<ServiceProvider[]>,
  },
  computed: {
    headers() {
      return [
        { text: "", value: "picture", width: 50, sortable: false },
        { text: this.$i18n.t("object.person.firstName"), value: "firstName" },
        { text: this.$i18n.t("object.person.lastName"), value: "lastName" },
        { text: this.$i18n.t("object.person.email"), value: "email" },
        { text: this.$i18n.t("object.person.phoneNo"), value: "phoneNumber" },
        { text: this.$i18n.t("object.person.address"), value: "address" },
        { text: this.$i18n.t("object.person.postalCode"), value: "postalCode" },
        { text: this.$i18n.t("object.person.city"), value: "city" },
        { text: this.$i18n.t("object.person.percentage"), value: "percentage" },
        {
          text: this.$i18n.t("object.allocation.decision"),
          value: "active",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ] as DataTableHeader[];
    },
    activeStatus() {
      return [
        { text: "Disabled", value: EnumBoolean.false, color: "error" },
        { text: "Enabled", value: EnumBoolean.true, color: "success" },
      ];
    },
  },
  methods: {
    showMoreItem(item: ServiceProvider) {
      if (item && item.id) {
        this.$router.push({
          name: ROUTE_WORKER_PROFILE,
          params: { locale: this.$route.params.locale, id: item.id.toString() },
        });
      }
      this.$emit("showMoreItem", item);
    },
    createNew() {
      this.$emit("new");
    },
    onSearch(text: string) {
      this.search = text;
    },
    getSPStatus(item: ServiceProvider) {
      return this.activeStatus.find((x) => x.value == item.active) ?? null;
    },
  },
});
</script>
