import Cookies from "js-cookie";

/**
 *
 * @param name name of cookie to be added
 * @param value cookie value
 * @param expiry
 * @returns
 */
export function setCookies(
  name: string,
  value: string,
  options?: Cookies.CookieAttributes
) {
  Cookies.set(name, value, options);
}

/**
 *
 * @param name name of cookie
 * @returns
 */
export function getCookies(name: string) {
  return Cookies.get(name);
  const cookies = document.cookie.split(";");
  return cookies.find((cookie) => cookie.includes(name));
}

export function deleteCookie(name: string, options?: Cookies.CookieAttributes) {
  Cookies.remove(name, options);
}

export function getAllCookies() {
  return Cookies.get();
}
