<template>
  <span> <adminComponent /> </span>
</template>

<script lang="ts">
import AdminComponent from "@/components/admin/admin-component.vue";
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import Vue from "vue";

export default Vue.extend({
  name: "AdminView",
  components: {
    adminComponent: AdminComponent,
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
  },
  mounted() {
    this.loading = true;
    serviceproviderModule
      .retrieveServiceProviderCompleteList()
      .finally(() => (this.loading = false));
  },
});
</script>

<style></style>
