<template>
  <span class="text-center">
    <v-menu
      absolute
      bottom
      offset-x
      transition="slide-y-transition"
      :close-on-content-click="false"
      :nudge-width="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fab small text color="primary">
          <v-avatar color="primary" class="pa-4 mx-1 white--text">
            <v-icon v-if="!userInitials">mdi-account-circle</v-icon>
            <strong v-else>
              {{ userInitials }}
            </strong>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar color="primary" class="justify-center">
              <v-icon v-if="!userInitials">mdi-account-circle</v-icon>
              <strong v-else class="text-center white--text">
                {{ userInitials }}
              </strong>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <strong>
                  {{ user.name }}
                </strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="darkMode" inset :label="darkModeToggleText" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="language"
                :true-value="languageList[0]"
                :false-value="languageList[1]"
                inset
                :label="currentLanguage"
              />
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-menu
              v-model="pallete"
              offset-y
              max-width="290"
              offset-x
              rounded="lg"
              transition="slide-x-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-content
                  v-bind="attrs"
                  v-on="on"
                  class="primary--text"
                >
                  Pick color
                </v-list-item-content>
              </template>

              <v-card class="rounded-lg">
                <v-container>
                  <v-row dense no-gutters>
                    <v-col
                      v-for="(color, index) in colorList"
                      :key="index"
                      cols="4"
                      class="d-flex justify-center"
                    >
                      <v-btn
                        class="ma-2"
                        :color="color.base"
                        fab
                        small
                        @click="selectColor(color)"
                      ></v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-menu>
          </v-list-item>
        </v-list>

        <v-list dense>
          <v-list-item
            dense
            color="primary"
            @click="logout"
            :class="'error--text'"
          >
            {{ $t("site.access.logout") }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </span>
</template>

<script lang="ts">
import { logoutUser } from "@/helpers/router-helpers";
import { availableLanguages, convertLocaleToUrlLocale } from "@/plugins/i18n";
import { colorList, pickColor } from "@/plugins/vuetify";
import { LoginUser } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { Color } from "vuetify/lib/util/colors";

type ItemsType<T = string> = {
  text: T;
  value: T;
  color?: T;
  disabled?: boolean;
};

type PropType = { user: LoginUser };
type MethodType = {
  onItemClick(item: string): void;
  logout(): void;
  selectColor(color: Color): void;
};
type ComputeType = {
  userInitials: string | null;
  items: ItemsType[];
  darkMode: boolean;
  darkModeToggleText: TranslateResult;
  colorList: Color[];
  currentLanguage: string;
  languageList: string[];
  language: string;
};
type DataType = { pallete: boolean };

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "AppBarProfileAvatarComponent",
  data: () => ({
    pallete: false,
  }),
  computed: {
    language: {
      get() {
        return this.$route.params.locale;
      },
      set(value) {
        const to = this.$router.resolve({
          params: { locale: value },
          query: this.$route.query,
        });
        this.$router.push(to.location);
      },
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
    languageList() {
      return availableLanguages.map((x) => convertLocaleToUrlLocale(x));
    },
    colorList() {
      return colorList;
    },
    darkModeToggleText() {
      return this.$vuetify.theme.dark
        ? this.$i18n.t("site.btn.brightMode")
        : this.$i18n.t("site.btn.darkMode");
    },
    darkMode: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(value) {
        this.$vuetify.theme.dark = value;
      },
    },
    items() {
      return [{ text: "Logout", value: "logout", color: "error" }];
    },
    userInitials() {
      const nameArray = this.user?.name.split(" ");
      const firstName = nameArray?.[0][0];
      const secondName = nameArray?.[1][0];
      const initials = `${firstName}${secondName}`;
      if (!firstName || !secondName) return null;
      return initials.toLocaleUpperCase();
    },
  },
  props: {
    user: { type: Object },
  },
  methods: {
    onItemClick(item: string) {
      switch (item) {
        case "profile":
          {
            console.log("profile");
          }
          break;
        case "logout":
          {
            this.logout();
          }
          break;

        default:
          console.log(item);
          break;
      }
    },
    logout() {
      logoutUser();
    },
    selectColor(color: Color) {
      pickColor(color);
    },
  },
});
</script>

<style></style>
