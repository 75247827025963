<template>
  <v-bottom-navigation v-model="value" app color="primary">
    <v-btn
      v-for="(route, i) in routes"
      :value="route.name"
      :key="i"
      :to="{ name: route.name, params: { locale } }"
    >
      <small class="mt-1">{{ route.meta?.nav?.title() }}</small>

      <v-icon>{{ route.meta?.nav?.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script lang="ts">
import { calcRouteValues } from "@/helpers/router-helpers";
import { convertLocaleToUrlLocale } from "@/plugins/i18n";
import Vue from "vue";
import { RouteRecord } from "vue-router";

export default Vue.extend({
  name: "BottomNavigation",
  data: () => ({
    value: null as string | null,
  }),
  computed: {
    routes() {
      return this.$router
        .getRoutes()
        .filter((x) => calcRouteValues(x as RouteRecord)) as (RouteRecord & {
        children: RouteRecord;
      })[];
    },
    locale() {
      return convertLocaleToUrlLocale(this.$i18n.locale);
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    "$route.name": function (val) {
      const filter = this.routes.find((y) => y.name === val);
      this.value = filter ? filter.name ?? null : null;
    },
  },
});
</script>

<style></style>
