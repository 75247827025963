import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";
import {
  Allocation,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model";
import { ApprovalService } from "@/services/approval-service";

@Module({
  name: "spdecision",
  store,
  dynamic: true,
})
class SPDecisionModule extends VuexModule {
  allocation = new Allocation();
  pendings = new Array<Allocation>();
  id = new Number();
  ticketNumber = "";

  get task(): Task | undefined {
    return this.allocation?.task;
  }
  get serviceProvider(): ServiceProvider | undefined {
    return this.allocation?.allocatedTo;
  }

  @Action({ commit: "setAllocation" })
  retrieveAllocation(val: { id: string; ticketNumber: string }) {
    return new ApprovalService()
      .getApproval(val.ticketNumber, val.id)
      .then((res) => {
        if (res) return res;
      });
  }

  @Action({ commit: "updateFromSP" })
  updateAction(object: Allocation) {
    return new ApprovalService()
      .updateApproval(object)
      .then((res) => {
        if (res) return res;
      })
      .finally(() => {
        this.retrieveAllocation({
          id: this.id.toString(),
          ticketNumber: this.ticketNumber,
        });
      });
  }

  @Mutation
  setAllocation(allocation: { current: Allocation; pendings: Allocation[] }) {
    this.allocation = new Allocation();
    this.pendings = [];
    this.allocation = allocation.current;
    this.pendings = allocation.pendings;
    this.id = this.allocation.id as number;
    this.ticketNumber = this.allocation.task.ticketNumber;
  }

  @Mutation
  updateFromSP(allocation: Allocation) {
    this.allocation = new Allocation();
    Object.assign(this.allocation, allocation);
    console.log(allocation);
  }
}

export default getModule(SPDecisionModule);
