import { handleGlobalError } from "@/helpers/errors";
import {
  TASK_DETAIL_ROUTE,
  TASK_ROUTE,
  Task,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

const url = TASK_ROUTE;
export class TaskService extends BaseService<Task> {
  constructor() {
    super(url);
  }

  getOneByTicketNumber(ticketNumber: string) {
    return this.axios
      .get(TASK_DETAIL_ROUTE, { params: { ticketNumber } })
      .then((res) => res.data as Task)
      .catch((err) => handleGlobalError(err));
  }
}
