<template>
  <v-card flat color="transparent" class="pa-0">
    <v-container v-for="(info, i) in information" :key="i" class="mt-auto px-1">
      <v-card-title class="py-0 justify-center">
        <strong> {{ info.title }} </strong>
      </v-card-title>

      <v-divider class="py-2" />

      <v-row dense v-for="(client, i) in info.value" :key="i">
        <v-col cols="6" align="start">
          {{ client.title }}
        </v-col>
        <v-col cols="6" align="end">
          <strong> {{ client.value }} </strong>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions class="mt-10 pt-10">
      <v-row align-content="center" justify="start">
        <v-col cols="12" md="2" align-self="start">
          <v-btn
            block
            large
            class="mt-auto"
            color="primary"
            @click="showPriceDialog"
          >
            {{ $t("object.approval.step.header2") }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn block large outlined color="error" @click="showCancelDialog">
            {{ $t("site.btn.decline") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <decisionDialogComponent
      v-model="priceDialog"
      @submit="finish"
      @abort="hidePriceDialog"
    />

    <approvalCancelComponent
      v-model="cancelDialog"
      @abort="hideCancelDialog"
      @submit="cancel"
    />
  </v-card>
</template>

<script lang="ts">
import { tastTimeDisplay } from "@/helpers/component-helper";
import { finishTask, setDeclineTask } from "@/helpers/decision-helpers";
import { InformationType } from "@/model";
import { Allocation } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import ApprovalCancelComponent from "./approvalcancel-component.vue";
import DecisionDialogComponent from "./decisiondialog-component.vue";

type Information = { title: TranslateResult; value: InformationType[] };
type PropType = { value: Allocation };
type MethodType = {
  finish(val: number | string): void;
  cancel(val: string): void;
  showPriceDialog(): void;
  hidePriceDialog(): void;
  setLoading(val: boolean): void;
  showCancelDialog(): void;
  hideCancelDialog(): void;
};
type DataType = { priceDialog: boolean; cancelDialog: boolean | null };
type ComputedType = {
  information: Information[];
};
export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "ApprovalSecondStageComponent",
  data: () => ({
    priceDialog: false,
    cancelDialog: null,
  }),
  components: {
    decisionDialogComponent: DecisionDialogComponent,
    approvalCancelComponent: ApprovalCancelComponent,
  },
  props: {
    value: { type: Object },
  },
  computed: {
    information() {
      const task = this.value?.task;
      return [
        {
          title: this.$i18n.t("object.task.taskInfo"),
          value: [
            { title: this.$i18n.t("object.task.title"), value: task?.title },
            {
              title: this.$i18n.t("object.task.description"),
              value: task?.description,
            },
            {
              title: this.$i18n.t("object.task.appTime"),
              value: tastTimeDisplay(task, this.$route) as string,
            },
            { title: this.$i18n.t("object.task.street"), value: task.address },
            {
              title: this.$i18n.t("object.person.postalCode"),
              value: task.postalCode,
            },
            {
              title: this.$i18n.t("object.person.city"),
              value: task.city,
            },
          ],
        },
        {
          title: this.$i18n.t("object.misc.clientInfo"),
          value: [
            {
              title: this.$i18n.t("object.person.firstName"),
              value: task?.requesterFirstName,
            },
            {
              title: this.$i18n.t("object.person.lastName"),
              value: task?.requesterLastName,
            },
            {
              title: this.$i18n.t("object.misc.clientNo"),
              value: task?.requesterPhoneNo,
            },
          ],
        },
      ];
    },
  },
  methods: {
    finish(val) {
      const allocation = this.value;
      allocation.task.price = parseInt(val as string);
      this.setLoading(true);
      finishTask(this.value).finally(() => {
        this.hidePriceDialog();
        this.setLoading(false);
      });
    },
    cancel() {
      this.setLoading(true);
      setDeclineTask(this.value).finally(() => {
        this.hideCancelDialog();
        this.setLoading(false);
      });
    },
    setLoading(val) {
      this.$emit("load", val);
    },
    showPriceDialog() {
      this.priceDialog = true;
    },
    hidePriceDialog() {
      this.priceDialog = false;
    },
    showCancelDialog() {
      this.cancelDialog = true;
    },
    hideCancelDialog() {
      this.cancelDialog = false;
    },
  },
});
</script>
