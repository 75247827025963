<template>
  <span>
    <serviceproviderDetailsComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import ServiceproviderDetailsComponent from "./serviceproviderdetails-component.vue";

export default Vue.extend({
  name: "ServProviderDetailsPageComponent",
  components: {
    serviceproviderDetailsComponent: ServiceproviderDetailsComponent,
  },
});
</script>

<style></style>
