<template>
  <v-card flat class="pa-2">
    <v-card-title class="mx-auto font-weight-bold text-h4">
      {{ $t("site.access.login") }}
    </v-card-title>
    <v-form>
      <v-card class="pa-5" flat>
        <v-text-field
          :label="$t('site.access.username')"
          v-model="user.username"
          @keydown.enter="loginUser"
          outlined
          clearable
          clear-icon="mdi-close-circle-outline"
        />

        <v-text-field
          :label="$t('site.access.password')"
          v-model="user.password"
          :append-icon="passwordIcon"
          :type="showPasswordText ? 'text' : 'password'"
          outlined
          clearable
          @keydown.enter="loginUser"
          clear-icon="mdi-close-circle-outline"
          @click:append="showPasswordText = !showPasswordText"
        />
        <v-btn block large color="primary" @click="loginUser">
          {{ $t("site.access.login") }}
        </v-btn>

        <v-card-text class="mb-0 pb-0 primary--text">
          Or Continue With:
        </v-card-text>
        <v-card-actions class="justify-center align-center">
          <v-btn @click="mslogin" block :color="'primary'" outlined>
            <v-avatar size="25" rounded class="mx-4">
              <v-img src="../../../assets/microsoft-svg.svg" />
            </v-avatar>
            Microsoft
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-card>
</template>

<script lang="ts">
import { loginUser } from "@/helpers/router-helpers";
import { appName } from "@/model/config";
import { colorMainBackground } from "@/plugins/vuetify";
import { MsalService } from "@/services/msal-service";
import { LoginUser } from "@taskmanagement/taskapp-model";
import Vue from "vue";

export default Vue.extend({
  name: "LoginFormComponent",
  data: () => ({
    appName: appName,
    user: new LoginUser(),
    showPasswordText: false,
  }),
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
    passwordIcon() {
      if (!this.user.password) {
        return "";
      }
      return this.showPasswordText ? "mdi-eye-outline" : "mdi-eye-off-outline";
    },
    backgroundColor() {
      return colorMainBackground;
    },
  },
  mounted() {
    // console.log(this.$router.currentRoute);
  },
  methods: {
    loginUser() {
      this.loading = true;
      loginUser(this.user).finally(() => {
        this.loading = false;
      });
    },
    mslogin() {
      new MsalService().login();
    },
  },
});
</script>

<style></style>
