<template>
  <span>
    <v-card-actions>
      <v-btn
        rounded
        text
        color="primary"
        @click="$router.back()"
        style="text-transform: none"
      >
        <v-icon left large>mdi-chevron-left</v-icon>
        {{ $t("object.label.back") }}
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <taskFormComponent
      :today="today"
      v-model="task"
      @onClose="onClose"
      @onSubmit="onSubmit"
    />
  </span>
</template>

<script lang="ts">
import taskModule from "@/store/modules/task-module";
import { Task } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import TaskFormComponent from "./taskform-component.vue";

type MethodType = { loadOrder(): void; onSubmit(): void; onClose(): void };
type ComputedType = { task: Task };
type DataType = Record<string, string>;
type PropType = { value: Task; today: string };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "EditOrderComponent",
  components: {
    taskFormComponent: TaskFormComponent,
  },
  data: () => ({}),
  props: {
    value: { type: Task },
    today: { type: String },
  },
  computed: {
    task: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.loadOrder();
  },
  methods: {
    loadOrder() {
      const ticketNumber = this.$route.params.ticketNumber;
      if (!ticketNumber) return;
      taskModule.retrieveTaskByTicketNumber(ticketNumber);
    },
    onClose() {
      this.$router.back();
    },
    onSubmit() {
      taskModule.updateTask(this.task).then(() => {
        this.$router.back();
      });
    },
  },
});
</script>

<style></style>
