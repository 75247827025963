<template>
  <v-card flat color="transparent">
    <v-container class="align-center justify-center my-auto px-0">
      <span class="justify-center my-2 py-6">
        <v-skeleton-loader type="heading" />
      </span>

      <v-divider class="py-4" />

      <v-row v-for="(_, i) in 8" :key="i">
        <v-col cols="6" align="start"> <v-skeleton-loader type="text" /></v-col>
        <v-col cols="6" align="end">
          <strong> <v-skeleton-loader type="sentences" /> </strong>
        </v-col>
      </v-row>

      <v-card-actions class="mt-10 pt-10">
        <v-row align-content="center" justify="start">
          <v-col cols="12" md="2" align-self="start">
            <v-skeleton-loader type="button" />
          </v-col>
          <v-col cols="12" md="2">
            <v-skeleton-loader type="button" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "DecisionLoadingComponent",
});
</script>
