<template>
  <span>
    <v-card class="pa-4" light flat tile>
      <v-row dense no-gutters>
        <v-col cols="6"></v-col>
        <v-col cols="6">
          <v-img>
            <v-sheet elevation="4" rounded="lg" class="pa-2 ma-2 transparent">
              <!-- color="light-blue lighten-5" -->
              <v-row dense no-gutters>
                <v-col cols="3">
                  <v-img
                    alt=""
                    src="../../../assets/worker-svg.svg"
                    max-height="80"
                    max-width="80"
                    transition="ease-in-out"
                  />
                </v-col>
                <v-col cols="9" align-self="start">
                  <v-card-title class="text-h6 text-start">
                    Company Name
                  </v-card-title>
                  <v-card-subtitle class="text-start">
                    company slogan...
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-sheet>
          </v-img>
        </v-col>
      </v-row>
      <v-card flat tile class="mt-16">
        <v-row dense no-gutters class="mt-12">
          <v-col cols="6" class="align-start text-left" align-self="start">
            <small> beispeile GmbH | Postfach 12345 | 1234 Essen</small>
            <v-card-text class="mt-4 mx-auto text-left">
              {{ getFullName(serviceProvider) }} <br />
              {{ serviceProvider.address }}<br />
              {{ serviceProvider.postalCode }} {{ serviceProvider.city }} <br />
              {{ serviceProvider.country }}
            </v-card-text>
          </v-col>
          <v-col cols="6" class="grey lighten-2 text-start">
            <v-card flat class="pa-4" style="background-color: lightgrey">
              <span> Zahlungsinformationen auf einem Blick </span>
              <v-row dense no-gutters justify="start" class="mt-2">
                <v-col cols="4" class="text-start">
                  <small> Kontoinhaber</small>
                </v-col>
                <v-col cols="8" class="text-end">Company - Example GmbH</v-col>
              </v-row>
              <v-row dense no-gutters justify="start" class="mt-2">
                <v-col cols="4" class="text-start">
                  <small> IBAN</small>
                </v-col>
                <v-col cols="8" class="text-end">DE12345678907483</v-col>
              </v-row>
              <v-row dense no-gutters class="mt-2">
                <v-col cols="4" class="text-start">
                  <small> Verwendungszweck</small>
                </v-col>
                <v-col cols="8" class="text-end">
                  {{ invoice.invoiceNumber }}
                </v-col>
              </v-row>
              <v-row dense no-gutters class="mt-2">
                <v-col cols="4" class="text-start">
                  <small> Betrag</small>
                </v-col>
                <v-col cols="8" class="text-end">{{
                  formatAmount(invoice.amount)
                }}</v-col>
              </v-row>
              <v-row dense no-gutters class="mt-2">
                <v-col cols="4" class="text-start">
                  <small> Fälligkeit</small>
                </v-col>
                <v-col cols="8" class="text-end">
                  {{ invoice.paymentDue }}
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-card-text class="text-right font-weight-medium" flat>
          Berlin, {{ invoice.invoiceDate }}
        </v-card-text>
        <v-row dense>
          <v-col cols="6">
            <v-list flat class="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-start">
                    Bestellnummer
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-start">
                    <v-chip small class="pa-2">
                      {{ invoice.invoiceNumber }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6">
            <v-list flat class="transparent">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-start">
                    Rechnungsdatum
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-start">
                    {{ invoice.invoiceDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>

      <v-card-text class="text-start">
        {{
          $t("object.invoice.letterGreeting", [getFullName(serviceProvider)])
        }},
        <br />
        {{ $t("object.invoice.letterBody") }}
      </v-card-text>

      <v-card outlined class="my-6">
        <v-data-table
          :dense="$vuetify.breakpoint.mdAndDown"
          hide-default-footer
          :headers="headers"
          :items="tasks"
        >
        </v-data-table>
      </v-card>
      <v-toolbar style="background-color: gray" dense class="mt-10" flat>
        <v-spacer />
        <strong>{{ formatAmount(invoice.amount) }}</strong>
      </v-toolbar>
      <!-- <v-footer color="transparent" class="mt-4"> This is a footer</v-footer> -->
    </v-card>
  </span>
</template>

<script lang="ts">
import { formatAmount } from "@/helpers/component-helper";
import invoiceModule from "@/store/modules/invoice-module";
import { Invoice, ServiceProvider, Task } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { DataTableHeader } from "vuetify";

type DataType = Record<string, string>;
type MethodType = {
  getFullName(item: ServiceProvider): string;
  formatAmount(item: string | number | undefined): string;
};
type PropType = { value: Invoice };
type ComputeType = {
  tasks: Task[];
  serviceProvider: ServiceProvider;
  headers: DataTableHeader[];
  invoice: Invoice;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "InvoicePdfComponent",
  data: () => ({}),
  props: {
    value: { type: Invoice },
  },
  computed: {
    tasks() {
      return this.value?.tasks ?? [];
    },
    serviceProvider() {
      return this.invoice.serviceProvider ?? new ServiceProvider();
    },
    headers() {
      return [
        { text: "Ticket-Nummer", value: "ticketNumber", align: "start" },
        { text: "Charged Price (€)", value: "price", align: "start" },
        { text: "Invoiced Price (€)", value: "invoicedAmount", align: "start" },
      ] as DataTableHeader[];
    },
    invoice() {
      return invoiceModule.invoice;
    },
  },
  methods: {
    getFullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    formatAmount(item) {
      const locale = this.$route.params.locale; //as string;
      return formatAmount(parseInt(item as string), locale);
    },
  },
});
</script>

<style></style>
