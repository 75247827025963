import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import { i18n } from "./plugins/i18n";
import router from "./plugins/router";
import "./plugins/toast";
import vuetify from "./plugins/vuetify";
import store from "./store";
import App from "./views/App.vue";

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
