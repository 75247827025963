<template>
  <span>
    <v-card
      flat
      class="ma-2"
      :max-height="$vuetify.breakpoint.mdAndUp ? 700 : null"
    >
      <v-card flat>
        <v-row>
          <v-col cols="12" md="4">
            <v-card flat tile class="px-4">
              <v-btn
                absolute
                outlined
                :color="formatStatus?.color"
                right
                small
                class="ma-4"
              >
                {{ formatStatus?.text }}
              </v-btn>
              <v-card-title class="justify-center">
                <v-avatar color="orange lighten-4">
                  <strong style="color: #fb8c00">{{ nameInitials }}</strong>
                </v-avatar>
              </v-card-title>
              <strong class="text-h5 mb-0" style="font-weight: 600">
                {{ fullName }}
              </strong>
              <v-card-subtitle class="pt-0">
                {{ serviceProvider.email }}
              </v-card-subtitle>

              <v-card outlined class="mx-6">
                <v-row no-gutters>
                  <v-col cols="4" v-for="(data, i) in historyData" :key="i">
                    <v-list-item two-line dense>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          {{ data.text }}
                        </v-list-item-subtitle>
                        <v-list-item-title>
                          <strong>{{ data.value }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card>
              <v-card flat>
                <v-card flat>
                  <v-card-title class="pb-0">
                    <strong> {{ $t("object.misc.detail") }} </strong>
                  </v-card-title>
                  <v-divider />
                  <v-card class="text-start px-5" eager flat>
                    <v-list flat class="pa-0">
                      <v-list-item
                        three-line
                        v-for="(itm, i) in detailsList"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <strong>
                              {{ itm.title }}
                            </strong>
                          </v-list-item-title>
                          <v-card flat class="px-4">
                            <v-list-item-subtitle>
                              {{ itm.line1 }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ itm.line2 }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ itm.line3 }}
                            </v-list-item-subtitle>
                          </v-card>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="pa-0"
                        expand-icon="mdi-menu-down"
                      >
                        Metadata
                      </v-expansion-panel-header>
                      <v-divider />
                      <v-expansion-panel-content>
                        <v-list dense flat subheader>
                          <v-list-item dense class="pa-0">
                            <v-list-item-content class="pa-0">
                              <v-btn
                                plain
                                v-if="serviceProvider.active == boolean.true"
                                @click="deactivate"
                                color="error"
                                class="text-start justify-start pa-0"
                                :ripple="false"
                              >
                                {{ $t("object.admin.deactivateText") }}
                              </v-btn>
                              <v-btn
                                v-if="serviceProvider.active == boolean.false"
                                plain
                                @click="activate"
                                color="primary"
                                class="text-start justify-start pa-0"
                                :ripple="false"
                              >
                                {{ $t("object.admin.activateText") }}
                              </v-btn>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </v-card>
            </v-card>
          </v-col>
          <v-divider vertical v-if="$vuetify.breakpoint.lgAndUp" />
          <v-col cols="12" md="8">
            <v-card flat class="my-4">
              <v-card-title class="py-0">
                <strong>
                  {{ $t("object.task.listName") }}
                </strong>
              </v-card-title>
              <v-card
                outlined
                class="mx-4 pt-0 overflow-y-auto"
                style="scrollbar-width: thin"
                max-height="250"
              >
                <v-data-table
                  :dense="$vuetify.breakpoint.mdAndDown"
                  :items="serviceProvider.tasks"
                  :headers="taskHeaders"
                  hide-default-footer
                  fixed-header
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn @click="gotoTask(item)" fab x-small icon>
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ timeFormatter(item) }}
                  </template>
                  <template v-slot:[`item.stage`]="{ item }">
                    <statusDisplayComponent
                      small
                      :color="taskFormatter(item)?.color"
                      :text="taskFormatter(item)?.value"
                    />
                  </template>
                </v-data-table>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </span>
</template>

<script lang="ts">
import {
  formatAmount,
  headerEnumFormatter,
  headerTimeFormatter,
} from "@/helpers/component-helper";
import { activateSP, deactivateSP } from "@/helpers/serviceprovider-helper";
import { TypeStageFormatter } from "@/model";
import { ROUTE_ORDERDETAILS } from "@/plugins/router";
import formatterModule from "@/store/modules/formatter-module";
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import {
  EnumBoolean,
  EnumTaskStage,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { DataTableHeader } from "vuetify";
import StatusDisplayComponent from "../statusdisplay-component.vue";

type DisplayHelperType<T = string | number> = {
  value?: T;
  text: string | TranslateResult;
};
type StatusType = DisplayHelperType<EnumBoolean> & { color: string };
type PropType = Record<string, string>;
type DataType = { boolean: typeof EnumBoolean };
type ComputedType = {
  activeStatus: StatusType[];
  serviceProvider: ServiceProvider;
  nameInitials: string;
  historyData: DisplayHelperType[];
  taskHeaders: DataTableHeader[];
  bankDetails: DisplayHelperType[];
  detailsList: {
    title: string;
    line1?: string;
    line2?: string;
    line3?: string;
    line4?: string;
  }[];
  fullName: string | null;
  activeSince: string | null;
  formatStatus: StatusType | null;
};
type MethodType = {
  taskFormatter(item: Task): TypeStageFormatter<EnumTaskStage> | null;
  timeFormatter(item: Task): string;
  gotoTask(item: Task): void;
  deactivate(): void;
  activate(): void;
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "ServiceProviderDetailComponent",
  components: {
    statusDisplayComponent: StatusDisplayComponent,
  },
  data: () => ({
    boolean: EnumBoolean,
  }),
  computed: {
    serviceProvider() {
      return serviceproviderModule.serviceProvider;
    },
    nameInitials() {
      const sp = this.serviceProvider;
      const firstname = sp.firstName?.[0] ?? "";
      const lastName = sp.lastName?.[0] ?? "";
      const returnVal = firstname + lastName;
      return returnVal.toUpperCase();
    },
    historyData() {
      const sp = this.serviceProvider;
      const locale = this.$route.params.locale;
      return [
        {
          text: "Total Revenue(€)",
          value: formatAmount(sp.totalRevenue ?? 0, locale),
        },
        { text: "Since", value: this.activeSince ?? "" },
        { text: "Paid", value: formatAmount(sp.totalPaid ?? 0, locale) },
      ];
    },
    formatStatus() {
      const sp = this.serviceProvider;
      return this.activeStatus.find((x) => x.value === sp.active) ?? null;
    },
    activeStatus() {
      return [
        {
          text: this.$i18n.t("object.enum.disabled"),
          value: EnumBoolean.false,
          color: "error",
        },
        {
          text: this.$i18n.t("object.enum.enabled"),
          value: EnumBoolean.true,
          color: "success",
        },
      ];
    },
    taskHeaders() {
      return [
        {
          text: this.$i18n.t("object.task.header.ticketNo"),
          value: "ticketNumber",
        },
        { text: this.$i18n.t("object.task.header.price"), value: "price" },
        { text: this.$i18n.t("object.task.header.stage"), value: "stage" },
        {
          text: this.$i18n.t("object.task.header.createdOn"),
          value: "createdAt",
        },
        { value: "action" },
      ] as DataTableHeader[];
    },
    bankDetails() {
      const sp = this.serviceProvider;
      return [
        { text: "IBAN", value: sp.iban },
        { text: "BIC", value: sp.bic },
        { text: "Bank Name", value: sp.bankName },
      ];
    },
    detailsList() {
      const sp = this.serviceProvider;
      return [
        {
          title: "Contact Details",
          line1: sp.phoneNumber,
          line2: sp.email,
        },
        {
          title: "Bank Details",
          line1: sp.iban,
          line2: sp.bic,
          line3: sp.bankName,
        },
      ];
    },
    fullName() {
      const sp = this.serviceProvider;
      if (!sp.firstName && !sp.lastName) return null;
      return sp.firstName + " " + sp.lastName;
    },
    activeSince() {
      const sp = this.serviceProvider;
      return moment(sp.createdAt).format("MMM YYYY");
    },
  },
  methods: {
    taskFormatter(item: Task) {
      return headerEnumFormatter(item.stage, formatterModule.taskStage) ?? null;
    },
    timeFormatter(item) {
      return headerTimeFormatter(item?.createdAt);
    },
    gotoTask(item: Task) {
      this.$router.push({
        name: ROUTE_ORDERDETAILS,
        params: { ticketNumber: item.ticketNumber },
      });
    },
    deactivate() {
      const id = this.serviceProvider.id;
      if (id) deactivateSP(id).then((res) => console.log(res));
    },
    activate() {
      const id = this.serviceProvider.id;
      if (id) activateSP(id);
    },
  },
});
</script>
