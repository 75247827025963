import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const version = process.env.PACKAGE_VERSION;

export default new Vuex.Store({
  state: {
    isLoading: false,
    isLocalLoading: false,
    isNotification: false,
    isNavigationBarVisible: false,
    isAppBarSearchVisible: false,
    isAppBarSearchFilterVisible: false,
    version,
  },
  getters: {},
  mutations: {
    setLoading(state, val: boolean) {
      state.isLoading = val;
    },
    setLocalLoading(state, val: boolean) {
      state.isLocalLoading = val;
    },
    setIsNotification(state, val: boolean) {
      state.isNotification = val;
    },
    setNavigationBarVisible(state, val: boolean) {
      state.isNavigationBarVisible = val;
    },
    setAppBarSearchVisible(state, val: boolean) {
      state.isAppBarSearchVisible = val;
    },
    setAppBarSearchFilterVisible(state, val: boolean) {
      state.isAppBarSearchFilterVisible = val;
    },
  },
  actions: {},
  modules: {},
});
