import { LogLevel } from "typescript-logging";
import { CategoryProvider } from "typescript-logging-category-style";
import Vue from "vue";

// Optionally change default settings, in this example set default logging to Info.
// Without changing configuration, categories will log to Error.
const provider = CategoryProvider.createProvider("ClientProvider", {
  level: Vue.config.devtools ? LogLevel.Debug : LogLevel.Error,
});

// Create categories, they will autoregister themselves, one category without parent (root) and a child category.
export const catClient = provider.getCategory("client");

// Optionally get a logger for a category, since 0.5.0 this is not necessary anymore, you can use the category itself to log.
// export const log: CategoryLogger = CategoryServiceFactory.getLogger(cat);
export const catService = catClient.getChildCategory("service");
export const catHelper = catClient.getChildCategory("helper");
export const catStore = catClient.getChildCategory("storeModules");
export const catVue = catClient.getChildCategory("vueComponent");
