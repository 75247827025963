<template>
  <v-main>
    <v-card
      tile
      flat
      class="fill-height"
      :class="approval ? 'pa-2' : 'pa-6'"
      align="center"
      :color="backgroundColor"
    >
      <transition
        name="router-animation"
        enter-active-class="animation fadeIn"
        leave-active-class="animation fadeOut"
        mode="out-in"
      >
        <router-view />
      </transition>
    </v-card>
  </v-main>
</template>

<script lang="ts">
import { ROUTE_APPROVAL } from "@/plugins/router";
import Vue from "vue";
import { colorMainBackground } from "../plugins/vuetify";
export default Vue.extend({
  name: "MainComponent",
  computed: {
    backgroundColor() {
      return colorMainBackground;
    },
    isLargeScreen() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    approval() {
      return this.$route.name === ROUTE_APPROVAL;
    },
  },
});
</script>
