import { TypeStageFormatter } from "@/model";
import invoiceModule from "@/store/modules/invoice-module";
import { EnumInvoiceStage, Invoice } from "@taskmanagement/taskapp-model";
import { Vue } from "vue/types/vue";
import { InvoiceService } from "../services/invoice-service";

export class ComponentPDF {
  private component: Vue;
  private invoiceName = "invoice.pdf";

  constructor(component: Vue, invoiceName?: string) {
    this.component = component;
    this.invoiceName = invoiceName ?? this.invoiceName;
  }

  private html() {
    return `
    <body>
    ${this.component.$el?.innerHTML?.replace(/"/g, "'")}
    </body>`;
  }
  private getFileFromAPI() {
    return new InvoiceService()
      .generateInvoice({
        html: this.html(),
      })
      .then((pdf) => {
        const blob = new Blob([pdf], { type: "application/pdf" });
        return blob;
      });
  }

  downloadPDF() {
    return this.getFileFromAPI()
      .then((blob) => {
        const link = document.createElement("a");

        // Set the download attribute with a filename for the PDF
        link.download = "invoice.pdf";

        // Create a URL for the Blob and set it as the href attribute
        link.href = URL.createObjectURL(blob);

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click event on the link to initiate the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }

  printPDF() {
    return this.getFileFromAPI().then((blob) => {
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a new window for printing
      return window.open(blobUrl, "_blank");
    });
  }
}

export function createInvoiceObj<T>(invoice: Invoice, refresher?: () => T) {
  invoice.stage = EnumInvoiceStage.save;
  return invoiceModule
    .createInvAction(invoice)
    .then((x) => x)
    .finally(refresher);
}

export function draftInvoiceObj<T>(invoice: Invoice, refresher?: () => T) {
  invoice.stage = EnumInvoiceStage.draft;
  return invoiceModule
    .createInvAction(invoice)
    .then((x) => x)
    .finally(refresher);
}
export function deleteInvoiceObj<T>(invoice: Invoice, refresher?: () => T) {
  invoice.stage = EnumInvoiceStage.draft;
  return invoiceModule
    .deleteInvoice(invoice)
    .then((x) => x)
    .finally(refresher);
}
export function updateInvoiceObj<T>(invoice: Invoice, refresher?: () => T) {
  return invoiceModule
    .updateInvoice(invoice)
    .then((x) => x)
    .finally(refresher);
}

export function markInvoiceAsPaid<T>(invoice: Invoice, refresher?: () => T) {
  invoice.stage = EnumInvoiceStage.paid;
  return updateInvoiceObj(invoice, refresher);
}

export function sendInvoice<T>(invoice: Invoice, refresher?: () => T) {
  return invoiceModule.sendInvoice(invoice).finally(refresher);
}

export function getInvoiceWithCount(
  invoiceType: TypeStageFormatter<EnumInvoiceStage>[],
  invoiceList: Invoice[]
) {
  invoiceType.forEach((type) => {
    type.count = 0;
  });

  invoiceList.forEach((invoice) => {
    const stage = invoice.stage;

    if (!stage) {
      const stageType = invoiceType.find((typ) => typ.enum === stage);

      if (stageType) {
        stageType.count = (stageType.count ?? 0) + 1;
      }
    }
  });

  return invoiceType;
}
