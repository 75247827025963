<template>
  <v-container class="px-0">
    <v-row>
      <v-col cols="6" v-for="item in items" :key="item.id">
        <v-card
          :height="height"
          class="pa-0 d-flex align-center justify-center mx-auto"
          rounded="lg"
        >
          <v-list-item two-line>
            <v-list-item-avatar class="justify-center" v-if="item.icon">
              <v-icon large right :color="item.iconColor">
                {{ item?.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <strong class="float-left"> {{ item.title }} </strong>
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: small; font-weight: 600">
                <countDownTimerComponent
                  class="float-left"
                  v-if="item.id === 2 && awaitAccept"
                  :refTime="task?.serviceProviderViewTime"
                />
                <span v-if="item.id === 1">{{ item.value }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { formatTime, headerEnumFormatter } from "@/helpers/component-helper";
import { InformationType, TypeStageFormatter } from "@/model";
import formatterModule from "@/store/modules/formatter-module";
import {
  Allocation,
  EnumStatus,
  EnumTaskStage,
  Task,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import CountDownTimerComponent from "../countdowntimer-component.vue";

type PropType = { value: Allocation };
type MethodType = Record<string, string>;
type ComputedType = {
  formatTaskColor: TypeStageFormatter<EnumTaskStage> | null;
  items: InformationType[];
  task: Task;
  viewTime: string | null;
  awaitAccept: boolean;
  taskAccepted: boolean;
};
type DataType = { height: number; stage: typeof EnumTaskStage };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "DecisionHeaderComponent",
  data: () => ({
    height: 80,
    stage: EnumTaskStage,
  }),
  props: {
    value: { type: Object },
  },
  computed: {
    task() {
      return this.value.task;
    },
    viewTime() {
      return (this.task?.serviceProviderViewTime as string) ?? null;
    },
    items() {
      const day = this.viewTime
        ? moment(this.viewTime as string)
            .locale(this.$route.params.locale)
            .format("HH:mm")
        : "";
      const time = this.viewTime
        ? moment(this.viewTime as string)
            .locale(this.$route.params.locale)
            .format("dddd, DD MMMM YYYY")
        : "";
      const viewTime = this.viewTime ? formatTime(this.viewTime) : undefined;

      return [
        {
          id: 1,
          title: `Time of View: ${day}`,
          value: time,
        },
        {
          id: 2,
          title: this.awaitAccept
            ? this.$i18n.t("object.approval.validFor")
            : this.formatTaskColor?.value.toString() ?? "",
          icon: this.awaitAccept ? "mdi-clock-outline" : "mdi-timer-sand",
          iconColor: this.awaitAccept ? "error" : this.formatTaskColor?.color,
          value: viewTime,
          condition: this.awaitAccept,
        },
      ];
    },
    awaitAccept() {
      const allc = this.value;
      const waitAccept =
        this.task?.stage >= EnumTaskStage.created &&
        this.task?.stage < EnumTaskStage.accepted;
      const noDecision = !allc.decision;
      return noDecision && waitAccept;
    },
    taskAccepted() {
      const allc = this.value;
      const accepted =
        this.task?.stage >= EnumTaskStage.accepted &&
        this.task?.stage < EnumTaskStage.done;
      const decided = allc.decision === EnumStatus.accepted;
      return accepted && decided;
    },
    formatTaskColor() {
      const task = this.value.task;
      return headerEnumFormatter(task?.stage, formatterModule.taskStage);
    },
  },
  components: {
    countDownTimerComponent: CountDownTimerComponent,
  },
  methods: {},
});
</script>

<style></style>
