<template>
  <invoiceEntryComponent v-if="inEdit" v-model="invoice" />
  <invoicePreviewComponent
    v-else
    :invoiceStages="invoiceStages"
    v-model="invoice"
  />
</template>

<script lang="ts">
import invoiceModule from "@/store/modules/invoice-module";
import { EnumInvoiceStage } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { colors } from "vuetify/lib";
import InvoiceEntryComponent from "../subcomponent/invoice/invoiceentry-component.vue";
import InvoicePreviewComponent from "../subcomponent/invoice/invoicepreview-component.vue";

export default Vue.extend({
  name: "InvoiceDetailsComponent",
  components: {
    invoicePreviewComponent: InvoicePreviewComponent,
    invoiceEntryComponent: InvoiceEntryComponent,
  },
  computed: {
    invoice() {
      return invoiceModule.invoice;
    },
    invoiceStages() {
      return [
        {
          value: this.$i18n.t("object.invoice.stage.draft"),
          enum: EnumInvoiceStage.draft,
          color: colors.orange.darken2,
          icon: "mdi-pencil",
        },
        {
          value: this.$i18n.t("object.invoice.stage.saved"),
          enum: EnumInvoiceStage.save,
          color: colors.cyan.darken1,
          icon: "mdi-content-save",
        },
        {
          value: this.$i18n.t("object.invoice.stage.sent"),
          enum: EnumInvoiceStage.send,
          color: colors.lime.base,
          icon: "mdi-invoice-send",
        },
        {
          value: this.$i18n.t("object.invoice.stage.paid"),
          enum: EnumInvoiceStage.paid,
          color: colors.green.darken3,
          icon: "mdi-check-circle-outline",
        },
      ];
    },
    inEdit() {
      return this.$route.query.mode === "edit";
    },
  },
  mounted() {
    this.getInvoiceData();
  },
  methods: {
    getInvoiceData() {
      const invoiceNumber = this.$route.params.invoiceNumber;
      if (!invoiceNumber) {
        this.$router.back();
        return;
      }
      invoiceModule.retrieveInvoiceByInvNumber(invoiceNumber);
    },
  },
});
</script>

<style></style>
