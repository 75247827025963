<template>
  <v-navigation-drawer app :width="isLargeScreen ? 320 : 280" permanent>
    <v-card class="d-flex fill-height flex-column">
      <v-card flat tile>
        <v-img height="150">
          <v-list>
            <v-list-item class="pa-4">
              <v-sheet
                color="white"
                elevation="10"
                rounded="lg"
                height="64"
                width="64"
                class="pa-1"
              >
                <v-img
                  alt=""
                  src="../assets/worker-svg.svg"
                  max-height="72"
                  max-width="72"
                />
              </v-sheet>

              <v-spacer></v-spacer>
            </v-list-item>
          </v-list>
        </v-img>
      </v-card>
      <v-divider></v-divider>
      <v-list shaped>
        <template>
          <span v-for="route in routes" :key="route.path">
            <v-list-item
              dense
              :key="route.path"
              :to="{
                name: route.name,
                params: { locale: localeUrl },
              }"
              color="primary"
              class="my-2 pa-1"
              active-class="active-list-item"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon class="ml-3">
                  <v-icon>{{ route.meta?.nav?.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-bold"
                    style="font-size: medium"
                  >
                    {{ route.meta?.nav?.title() }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-sheet
                  v-if="active"
                  class="fill-height primary"
                  width="10"
                  style="left: 0; top: 0; position: absolute; height: 100%"
                />
              </template>
            </v-list-item>
          </span>
        </template>
      </v-list>

      <v-chip
        class="mt-auto justify-center"
        label
        outlined
        color="primary"
        style="border: 0 solid transparent"
      >
        <v-icon left>mdi-copyright</v-icon>
        {{ bottomLabel }}
      </v-chip>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { appName } from "@/model/config";
import Vue from "vue";
import { RouteRecord } from "vue-router";
import { calcRouteValues } from "../helpers/router-helpers";
import { convertLocaleToUrlLocale } from "../plugins/i18n";
import { colorMenuTileBackground } from "../plugins/vuetify";
import authModule from "../store/modules/auth-module";
export default Vue.extend({
  name: "NavbarComponent",
  data: () => ({
    drawer: null,
  }),
  computed: {
    computedDrawer: {
      get() {
        return this.$store.state.isNavigationBarVisible;
      },
      set(val) {
        return this.$store.commit("setNavigationBarVisible", val);
      },
    },
    routes() {
      return this.$router
        .getRoutes()
        .filter((x) => calcRouteValues(x as RouteRecord)) as (RouteRecord & {
        children: RouteRecord;
      })[];
    },
    localeUrl() {
      return convertLocaleToUrlLocale(this.$i18n.locale);
    },
    darkModeActive() {
      return this.$vuetify.theme.dark;
    },
    backgroundImage() {
      return this.$vuetify.theme.dark
        ? "applicationLogoBackgroundBlack.png"
        : "applicationLogoBackgroundWhite.png";
    },
    backgroundColor() {
      return colorMenuTileBackground;
    },

    isLoggedIn() {
      return authModule.isLoggedIn;
    },
    loggedInUser() {
      return authModule.activeUser;
    },
    isLargeScreen() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    bottomLabel() {
      return appName + " : " + this.$store.state.version;
    },
  },
  methods: {},
});
</script>
