<template>
  <v-dialog v-model="dialog" width="500">
    <v-card rounded="lg">
      <v-card-title class="pb-0">
        <strong>
          <small>{{ $t("object.dialog.priceDialog.header") }}</small>
        </strong>
      </v-card-title>

      <v-divider class="mx-4" />

      <v-card-subtitle v-if="number" class="mt-2 ml-0 success--text" dense>
        {{ $t("object.dialog.priceDialog.priceDesc", [price]) }}
      </v-card-subtitle>

      <v-card-subtitle v-else>
        <strong>{{ $t("object.dialog.priceDialog.subtitle") }}</strong>
      </v-card-subtitle>

      <v-text-field
        v-model="number"
        outlined
        filled
        :label="$t('object.dialog.enterAmount')"
        persistent-hint
        hide-spin-buttons
        :hint="$t('object.dialog.numbersOnly')"
        clearable
        clear-icon="mdi-close-circle-outline"
        prepend-inner-icon="mdi-currency-eur"
        class="mx-4"
        type="number"
      />

      <v-card-actions class="justify-space-between mt-4">
        <v-btn color="primary" large outlined @click="abort">
          {{ $t("site.btn.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" large @click="submit" :disabled="!number">
          {{ $t("site.btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { formatAmount } from "@/helpers/component-helper";
import Vue from "vue";

type DataType = { number: null | number };
type ComputedType = { dialog: boolean; price: string | null };
type MethodType = { submit(): void; abort(): void };
type PropType = { value: boolean };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "DecisionDialogComponent",
  data: () => ({
    number: null,
  }),
  props: {
    value: { type: Boolean },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    price() {
      if (this.number) return formatAmount(this.number);
      return null;
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.number);
    },
    abort() {
      this.$emit("abort");
    },
  },
});
</script>
