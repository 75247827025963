<template>
  <v-overlay
    opacity="1"
    v-model="vModel"
    dark
    :color="$vuetify.theme.dark ? 'black' : 'white'"
  >
    <span>
      <v-img src="../assets/fadestaggersquares-loader.svg" sizes="350" />
    </span>
  </v-overlay>
</template>

<script lang="ts">
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type PropType = { label: string | TranslateResult };
type DataType = Record<string, string>;
type ComputeType = { vModel: boolean };
type MethodType = Record<string, string>;

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "GlobalLoader",
  props: {
    label: { type: String },
  },
  computed: {
    vModel: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
  },
});
</script>
