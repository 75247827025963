<template>
  <span>
    <editOrderComponent v-if="edit" v-model="order" :today="today" />

    <taskPreviewComponent
      v-else
      v-model="order"
      :taskStageDesc="taskStageDesc"
      :taskStatusDesc="taskStatusDesc"
      class="align-center"
      @edit="onEdit"
      @back="onBack"
      @delete="onDelete"
    />

    <deleteComponent
      :subTitle="order.ticketNumber"
      v-model="deleteDialog"
      @abort="abortDelete"
      @confirm="confirmDelete"
    />
  </span>
</template>

<script lang="ts">
import { gotoPathQuery } from "@/helpers/component-helper";
import formatterModule from "@/store/modules/formatter-module";
import taskModule from "@/store/modules/task-module";
import moment from "moment";
import Vue from "vue";
import DeleteComponent from "../subcomponent/delete-component.vue";
import EditOrderComponent from "../subcomponent/order/editorder-component.vue";
import TaskPreviewComponent from "../subcomponent/order/taskpreview-component.vue";

export default Vue.extend({
  name: "OrderDetailsComponent",
  data: () => ({
    deleteDialog: false,
  }),
  components: {
    taskPreviewComponent: TaskPreviewComponent,
    deleteComponent: DeleteComponent,
    editOrderComponent: EditOrderComponent,
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLocalLoading;
      },
      set(value) {
        this.$store.commit("setLocalLoading", value);
      },
    },
    order() {
      return taskModule.task;
    },
    edit() {
      return this.$route.query.mode === "edit";
    },
    taskStageDesc() {
      return formatterModule.taskStage;
    },
    taskStatusDesc() {
      return formatterModule.taskStatus;
    },
    today() {
      return moment().format("DD-MM-YYYY");
    },
  },
  methods: {
    onEdit() {
      gotoPathQuery(this.$route, this.$router, { mode: "edit" });
    },
    onBack() {
      this.$router.back();
    },
    onDelete() {
      this.deleteDialog = true;
    },
    abortDelete() {
      this.deleteDialog = false;
    },
    confirmDelete() {
      this.deleteDialog = false;
      this.loading = true;
      taskModule.deleteTask(this.order).finally(() => {
        this.loading = false;
        this.$router.back();
      });
    },
  },
});
</script>

<style></style>
