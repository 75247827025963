<template>
  <v-container>
    <apexCharts
      type="bar"
      :options="options"
      :series="series"
      :height="height"
    />
  </v-container>
</template>

<script lang="ts">
import { ApexOptions } from "apexcharts";
import Vue from "vue";
import ApexCharts from "vue-apexcharts";
import { TranslateResult } from "vue-i18n";

type PropType = {
  series: ApexOptions["series"];
  labelList: string[];
  money: boolean;
  yaxisLabel: string;
  height: number;
  title: string | TranslateResult;
  subTitle: string | TranslateResult;
};
type DataType = Record<string, string>;
type ComputeType = { options: ApexOptions };
type MethodType = Record<string, string>;

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "BarChartComponent",
  components: {
    apexCharts: ApexCharts,
  },
  data: () => ({}),
  props: {
    series: { type: Array },
    labelList: { type: Array },
    money: { type: Boolean },
    height: { type: Number, default: 350 },
    yaxisLabel: { type: String },
    title: { type: String },
    subTitle: {
      type: String,
    },
  },
  computed: {
    options() {
      const spList = this.labelList;
      const toggleMoney = this.money;
      const yaxisLabel = this.yaxisLabel;
      const title = this.title;
      const subTitle = this.subTitle;
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          zoom: {
            enabled: true,
          },
          toolbar: {
            // autoSelected: "pan",
            show: true,
          },
        },
        legend: {
          position: "right",
          offsetX: 0,
          offsetY: 50,
        },
        title: {
          text: title,
          align: "left",
          // floating: true,
        },
        subtitle: {
          text: subTitle,
        },
        yaxis: {
          title: {
            text: yaxisLabel,
          },
          labels: {
            formatter(val) {
              return toggleMoney ? "€ " + val : val;
            },
          },
        },
        dataLabels: {
          formatter(val) {
            return toggleMoney ? "€ " + val : val;
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: spList,
        },
        fill: {
          opacity: 1,
          type: "gradient",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            // columnWidth: "50%",
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 600,
                },
              },
            },
          },
        },
      } as ApexOptions;
    },
  },
});
</script>

<style></style>
