import { QueryParams } from "@/model";
import { InvoiceService, TypeAllInvoice } from "@/services/invoice-service";
import { Invoice, ServiceProvider, Task } from "@taskmanagement/taskapp-model";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";

@Module({
  name: "invoice",
  store,
  dynamic: true,
})
class InvoiceModule extends VuexModule {
  invoiceList: Invoice[] = [];
  serviceProviders: Task[] = [];
  invoice = new Invoice();

  @Action({ commit: "getAllList" })
  retrieveInvoiceList(params?: QueryParams<ServiceProvider>) {
    return new InvoiceService().getAllInvoices(params).then((res) => {
      if (res) return res;
    });
  }

  @Action
  retrieveInvoiceByInvNumber(invoiceNumber: string) {
    const invoice = new Invoice();
    invoice.invoiceNumber = invoiceNumber;
    return new InvoiceService().getInvoiceByInvNumber(invoice).then((res) => {
      if (res) {
        this.context.commit("setInvoice", res);
        return res;
      }
    });
  }
  @Action
  retrieveInvoice(id: string) {
    return new InvoiceService().getById(parseInt(id)).then((res) => {
      if (res) {
        this.context.commit("setInvoice", res);
        return res;
      }
    });
  }

  @Action({ commit: "sentInvoice" })
  sendInvoice(invoice: Invoice) {
    return new InvoiceService().sendInvoice(invoice);
  }

  @Action({ commit: "createInvoice" })
  createInvAction(invoice: Invoice) {
    return new InvoiceService()
      .create(invoice)
      .then((res) => {
        if (res) {
          return res;
        }
      })
      .finally(this.retrieveInvoiceList);
  }

  @Action({ commit: "updateInvMutation" })
  updateInvoice(invoice: Invoice) {
    return new InvoiceService()
      .update(invoice)
      .then((res) => {
        if (res) return invoice;
      })
      .finally(this.retrieveInvoiceList);
  }

  @Action({ commit: "deleteInvMutation" })
  deleteInvoice(invoice: Invoice) {
    return new InvoiceService()
      .delete(invoice)
      .then((res) => {
        if (res) {
          return invoice;
        }
        return new Invoice();
      })
      .finally(this.retrieveInvoiceList);
  }

  @Mutation
  getAllList(allList: TypeAllInvoice) {
    this.invoiceList.length = 0;
    this.serviceProviders.length = 0;
    this.invoiceList = allList?.invoiceList ?? [];
    this.serviceProviders = allList?.pending ?? [];
  }

  @Mutation
  setInvoice(invoice: Invoice) {
    this.invoice = invoice;
  }

  @Mutation
  createInvoice(invoice: Invoice) {
    if (invoice && invoice.id) {
      // this.invoiceList.push(invoice);
    }
  }

  @Mutation
  updateInvMutation(invoice: Invoice) {
    invoice;
    // if (invoice) {
    //   this.invoiceList = updateArray(this.invoiceList, invoice.id, invoice);
    // }
  }

  @Mutation
  deleteInvMutation(invoice: Invoice) {
    if (invoice) {
      // this.invoiceList = deleteFromArray(this.invoiceList, invoice);
    }
  }
  @Mutation
  sentInvoice(invoice: Invoice) {
    if (invoice) {
      this.invoice = invoice;
    }
  }
}

export default getModule(InvoiceModule);
