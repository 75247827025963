<template>
  <v-dialog v-model="vModel" max-width="500">
    <v-card :loading="value">
      <v-card-title class="text-h5 text-center warning">
        {{ title ?? $i18n.t("object.misc.deleteTitle") }}
      </v-card-title>
      <v-card-text class="text-h6 text-center ma-2">
        Delete: {{ subTitle ?? $i18n.t("object.dialog.delete.subTitle") }} ?
      </v-card-text>
      <v-card-actions class="mt-6">
        <v-btn color="primary" @click="close" rounded>
          {{ $t("site.btn.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="error" outlined @click="confirm" rounded>
          {{ $t("site.btn.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

type PropType = {
  value: boolean;
  title: string | TranslateResult;
  subTitle: string | TranslateResult;
};
type MethodType = { confirm(): void; close(): void };
type ComputeType = {
  vModel: boolean;
};
type DataType = Record<string, string>;

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "DeleteComponent",
  props: {
    value: { type: Boolean },
    subTitle: {
      type: String,
    },
    title: { type: String },
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    close() {
      this.$emit("abort");
    },
  },
});
</script>

<style></style>
