<template>
  <span>
    <statisticsComponent />
    <globalLoader :value="loading" />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import StatisticsComponent from "../components/statistics/statistics-component.vue";
import statisticsModule from "../store/modules/statistics-module";

export default Vue.extend({
  name: "StatisticsView",
  components: {
    statisticsComponent: StatisticsComponent,
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
  },
  mounted() {
    this.loadStats();
  },
  methods: {
    loadStats() {
      this.loading = true;
      statisticsModule
        .retrieveServiceProviderStat()
        .finally(() => (this.loading = false));
    },
  },
});
</script>

<style></style>
