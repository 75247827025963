<template>
  <span>
    <invoiceEntryComponent
      v-if="routerNew"
      :serviceProviderList="allServProviders"
      :toggle="view"
      :openServProvider="serviceProviders"
      :defaultInvoice="defaultInvoice"
      v-model="invoice"
      @addTask="addRemoveTask"
      @draft="draft"
      @create="createInvoice"
    />

    <invoiceListingComponent
      v-else
      :invoiceList="invoiceList"
      :invoiceStages="invoiceStages"
      :smallScreen="forLargeScreen"
      @stageFilter="stageFilter"
      @newInvoice="triggerNewInvoice"
      @triggerPreview="triggerPreview"
    />
  </span>
</template>

<script lang="ts">
import { gotoPathQuery, headerEnumFormatter } from "@/helpers/component-helper";
import { ROUTE_HOME } from "@/plugins/router";
import formatterModule from "@/store/modules/formatter-module";
import invoiceModule from "@/store/modules/invoice-module";
import serviceproviderModule from "@/store/modules/serviceprovider-module";
import {
  EnumInvoiceStage,
  Invoice,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import {
  ComponentPDF,
  createInvoiceObj,
  deleteInvoiceObj,
  draftInvoiceObj,
  getInvoiceWithCount,
  updateInvoiceObj,
} from "../../helpers/invoice-helper";
import { TypeStageFormatter } from "../../model";
import InvoiceEntryComponent from "../subcomponent/invoice/invoiceentry-component.vue";
import InvoiceListingComponent from "../subcomponent/invoice/invoicelisting-component.vue";

type DataType = {
  view: boolean;
  invoice: Invoice;
  defaultInvoice: Invoice;
  filterStage: number | null;
  showNotification: boolean;
};
type ComputeType = {
  today: string;
  loading: boolean;
  routerPreview: boolean;
  routerNew: boolean;
  serviceProviders: Task[];
  allServProviders: ServiceProvider[];
  invoiceList: Invoice[];
  invoiceStages: TypeStageFormatter<EnumInvoiceStage>[];
  forLargeScreen: boolean;
};
type MethodType = {
  getInvoiceStage(item: Invoice): TypeStageFormatter<EnumInvoiceStage> | null;
  refreshLoader(): void;
  saveInvoice(): void;
  triggerNewInvoice(): void;
  triggerInvoiceEdit(item: Invoice): void;
  addRemoveTask(task: Task): void;
  printComponent(): void;
  triggerBack(): void;
  triggerPreview(item: Invoice): void;
  clearInvoice(): void;
  draft(): void;
  sendInvoice(): void;
  markPaidInvoice(): void;
  createInvoice(): void;
  updateInvoice(): void;
  deleteInvoice(): void;
  stageFilter(item: EnumInvoiceStage): void;
  cancelNotice(): void;
  acceptNotice(): void;
};

type PropType = Record<string, string>;

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "InvoiceComponent",
  components: {
    invoiceListingComponent: InvoiceListingComponent,
    invoiceEntryComponent: InvoiceEntryComponent,
  },
  data: () => ({
    view: false,
    filterStage: null,
    defaultInvoice: {
      ...new Invoice(),
      invoiceDate: null as Date | null,
      paymentDue: null as Date | null,
      description: "",
      tasks: [] as Task[],
      serviceProvider: {
        firstName: new String(),
        lastName: new String(),
        address: new String(),
      },
    } as Invoice,
    invoice: {
      ...new Invoice(),
      invoiceDate: null as Date | null,
      paymentDue: null as Date | null,
      description: "",
      tasks: [] as Task[],
      serviceProvider: {
        firstName: new String(),
        lastName: new String(),
        address: new String(),
      },
    } as Invoice,
    showNotification: false,
  }),
  watch: {
    view(val) {
      if (!val) {
        this.invoice = new Invoice();
        this.invoice = this.defaultInvoice;
      }
    },
    invoice(val: Invoice) {
      if (val.serviceProvider?.tasks) {
        val.serviceProvider.tasks = val.tasks;
      }
    },
  },
  created() {
    this.showNotification = this.forLargeScreen;
  },
  computed: {
    today() {
      return moment().format("DD-MM-YYYY");
    },
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
    routerPreview() {
      return this.$route.query.mode == "preview";
    },
    routerNew() {
      return this.$route.query.mode == "new";
    },
    serviceProviders() {
      return invoiceModule.serviceProviders;
    },
    allServProviders() {
      return serviceproviderModule.serviceProviderList;
    },
    invoiceList() {
      return invoiceModule.invoiceList.filter(
        (x) => x.stage == (this.filterStage ?? x.stage)
      );
    },
    invoiceStages() {
      return getInvoiceWithCount(
        formatterModule.invoiceStages,
        invoiceModule.invoiceList
      );
    },
    forLargeScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    saveInvoice() {
      if (this.invoice && this.invoice.id) {
        this.updateInvoice();
      }
      if (this.invoice && !this.invoice.id) {
        this.createInvoice();
      }
    },
    refreshLoader() {
      this.loading = false;
      this.view = false;
      this.invoice = this.defaultInvoice;
    },
    addRemoveTask(task: Task) {
      const foundIndex =
        this.invoice.tasks?.findIndex((x) => x.id === task.id) ?? -1;

      if (foundIndex !== -1) {
        this.$delete(this.invoice.tasks, foundIndex);
      } else {
        this.invoice.tasks?.push(task);
      }
    },
    draft() {
      if (this.invoice) {
        this.loading = true;
        draftInvoiceObj(this.invoice, this.refreshLoader);
      }
    },
    createInvoice() {
      if (this.invoice) {
        if (this.invoice.id) {
          this.updateInvoice();
        } else {
          this.loading = true;
          createInvoiceObj(this.invoice, this.refreshLoader);
        }
      }
    },
    updateInvoice() {
      if (this.invoice) {
        this.loading = true;
        updateInvoiceObj(this.invoice, this.refreshLoader);
      }
    },
    deleteInvoice() {
      if (this.invoice) {
        this.loading = true;
        deleteInvoiceObj(this.invoice, this.refreshLoader).finally(
          this.triggerBack
        );
      }
    },
    clearInvoice() {
      this.invoice = new Invoice();
      this.invoice = this.defaultInvoice;
    },
    triggerNewInvoice() {
      this.invoice = this.defaultInvoice;
      gotoPathQuery(this.$route, this.$router, {
        mode: "new",
      });
    },
    getInvoiceStage(item: Invoice) {
      const stage = item.stage;
      return headerEnumFormatter(stage, this.invoiceStages);
    },
    triggerInvoiceEdit(invoice) {
      this.invoice = invoice;
      this.view = true;
    },
    printComponent() {
      const component = this.$refs.invoicePdf as Vue;
      new ComponentPDF(component).printPDF();
    },
    triggerBack() {
      this.$router.back();
      this.invoice = this.defaultInvoice;
    },
    triggerPreview(item) {
      if (item && item.id) {
        const id = item.id.toString();
        gotoPathQuery(this.$route, this.$router, {
          mode: "preview",
          id,
        });
      }
    },
    stageFilter(item: EnumInvoiceStage) {
      this.filterStage = item;
    },
    sendInvoice() {
      this.refreshLoader();
    },
    markPaidInvoice() {
      //
    },
    cancelNotice() {
      this.$router.push({
        path: ROUTE_HOME,
        params: { locale: this.$route.params.locale },
      });
    },
    acceptNotice() {
      this.showNotification = false;
    },
  },
});
</script>

<style></style>
