import axios from "axios";
import moment from "moment";
import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "../locale/de-DE.json";
import { headerAcceptLanguage } from "../model/config";
import vuetify from "./vuetify";

Vue.use(VueI18n);

const germanLanguage = "de-DE";
const englishLanguage = "en-US";

export const storageLangKey = "_language";

export const defaultLanguage = germanLanguage;

export const availableLanguages = [englishLanguage, germanLanguage];
export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {
    [germanLanguage]: {
      ...de,
    },
  },
  silentFallbackWarn: !Vue.config.devtools,
});

setI18Language(germanLanguage);

function getLoadedLanguages(): string[] {
  return Object.keys(i18n.messages);
}

function setI18Language(lang: string): string {
  i18n.locale = lang;
  axios.defaults.headers.common[headerAcceptLanguage] =
    convertLocaleToUrlLocale(lang);

  const locale = getBrowserMainLanguage();
  moment.locale(locale);

  vuetify.framework.lang.current = convertLocaleToUrlLocale(lang);
  return lang;
}

export function getBrowserMainLanguage(): string {
  return window.navigator.language;
}

export function toggleBetweenLanguages(): string {
  return i18n.locale === englishLanguage ? germanLanguage : englishLanguage;
}

export function convertLocaleToUrlLocale(locale: string): string {
  return locale.substring(0, 2);
}

export function getUrlLocaleToLocale(locale: string): string | undefined {
  return availableLanguages.find((x) => x.startsWith(locale));
}

export function loadLanguageAsync(lang: string): Promise<string> {
  if (lang === undefined) {
    return Promise.resolve("No language defined");
  }
  // when same language is already loaded
  if (i18n.locale === lang || getLoadedLanguages().includes(lang)) {
    return Promise.resolve(setI18Language(lang));
  }

  return import(`../locale/${lang}.json`).then((message) => {
    i18n.setLocaleMessage(lang, message.default);
    return setI18Language(lang);
  });
}

export function changeLanguage(locale: string): string {
  i18n.locale = locale;
  return i18n.locale;
}
