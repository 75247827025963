<template>
  <span>
    <taskComponent color="transparent" />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import allocationModule from "../store/modules/allocation-module";

import TaskComponent from "./order/task-component.vue";

export default Vue.extend({
  name: "DashboardComponent",
  components: {
    taskComponent: TaskComponent,
  },
  data: () => ({
    time: null,
    tabs: null,
    tabValue: null,
    loading: false,
  }),
  computed: {
    loadingMsg() {
      return this.$i18n.t("site.loader.dashboard");
    },
    tabItems() {
      return [{ name: this.$i18n.t("object.task.listName"), value: "tasks" }];
    },
    allocations() {
      return allocationModule.allocationList;
    },
  },
});
</script>

<style></style>
