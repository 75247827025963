<template>
  <approvalComponent />
</template>

<script lang="ts">
import ApprovalComponent from "@/components/decision/approval-component.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ApprovalView",
  components: {
    approvalComponent: ApprovalComponent,
  },
  data: () => ({}),
});
</script>

<style></style>
