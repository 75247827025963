<template>
  <span>
    <v-toolbar
      class="primary white--text elevation-6 mb-4 text-center rounded-lg"
      max-width="500"
    >
      {{ $t("object.approval.openInComplete", [allocations.length]) }}
    </v-toolbar>

    <v-card
      v-for="item in allocations"
      class="mx-auto mb-4 rounded-lg"
      elevation="6"
      max-width="344"
      :key="item.id"
    >
      <v-card-text v-if="formatTime(item?.task?.startTime).past">
        <v-icon align="start" color="warning" :class="'mx-2'">
          mdi-information
        </v-icon>
        <small>{{ $t("object.approval.timePast") }}</small>
      </v-card-text>
      <v-card-text v-if="timedOut(item)">
        <v-icon align="start" color="warning" :class="'mx-2'">
          mdi-alert
        </v-icon>
        <small>{{ $t("object.task.timeOutText") }} </small>
      </v-card-text>
      <v-list dense>
        <v-list-item dense v-if="item.task.startTime">
          <v-icon
            :color="formatTime(item.task.startTime).past ? 'error' : 'primary'"
            class="mx-2"
            >mdi-clock</v-icon
          >
          {{ formatTime(item.task.startTime).taskTime }}
        </v-list-item>
        <v-list-item>
          <v-icon color="primary" class="mx-2">mdi-map-marker-outline</v-icon>
          {{ item.task.city }}
          {{ item.task.postalCode }}
        </v-list-item>
      </v-list>

      <v-card-actions draggable>
        <v-spacer />
        <v-btn
          color="primary"
          small
          rounded
          @click="triggerObj(item)"
          :disabled="timedOut(item)"
        >
          {{ $t("object.approval.finish") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </span>
</template>

<script lang="ts">
import { checkTaskTimeOut, formatTime } from "@/helpers/component-helper";
import spdecisionModule from "@/store/modules/spdecision-module";
import { Allocation } from "@taskmanagement/taskapp-model/dist";
import moment from "moment";
import Vue from "vue";
import { PropValidator } from "vue/types/options";

export default Vue.extend({
  name: "OpenTasksComponent",
  data: () => ({
    show: false,
  }),
  props: {
    allocations: { type: Array } as PropValidator<Allocation[]>,
  },
  methods: {
    triggerObj(item: Allocation) {
      const id = item.id?.toString() as string;
      const ticketNumber = item.task.ticketNumber;
      spdecisionModule
        .retrieveAllocation({
          id,
          ticketNumber,
        })
        .finally(() => {
          this.$router.push({ query: { ticketNumber, id } });
          this.$vuetify.goTo(0);
          this.$emit("approval");
        });
    },
    formatTime(time: any) {
      const locale = this.$route.params.locale;
      const taskTime = formatTime(time as string, undefined, locale);
      const past = moment(time).isBefore(moment());
      if (time) {
        return { taskTime, past };
      }
      return { taskTime: "", past: false };
    },
    timedOut(allocation: Allocation) {
      return checkTaskTimeOut(allocation.task);
    },
  },
});
</script>

<style></style>
