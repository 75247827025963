<template>
  <v-overlay v-model="loading">
    <v-progress-circular :size="size" :color="color" indeterminate>
      {{ label }}
    </v-progress-circular>
  </v-overlay>
</template>

<script lang="ts">
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { i18n } from "../plugins/i18n";
type PropType = {
  value: boolean;
  label: string | TranslateResult;
  color: string;
  size: number;
};
type MethodType = { close(): void };
type DataType = Record<string, string>;
type ComputeType = { loading: boolean };
export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "LocalLoadingComponent",
  props: {
    value: { type: Boolean, default: false },
    label: { type: String, default: i18n.t("site.loader.defaultLabel") },
    color: { type: String },
    size: { type: Number, default: 200 },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLocalLoading;
      },
      set(value) {
        this.$store.commit("setLocalLoading", value);
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
</script>

<style></style>
