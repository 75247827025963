<template>
  <orderDetailsComponent />
</template>

<script lang="ts">
import OrderDetailsComponent from "@/components/order/orderdetails-component.vue";
import taskModule from "@/store/modules/task-module";
import Vue from "vue";

export default Vue.extend({
  name: "OrderDetailsView",
  components: {
    orderDetailsComponent: OrderDetailsComponent,
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(value) {
        this.$store.commit("setLoading", value);
      },
    },
  },
  mounted() {
    this.loadOrderData();
  },
  methods: {
    loadOrderData() {
      const ticketNumber = this.$route.params.ticketNumber;
      this.loading = true;
      if (!ticketNumber) {
        this.$router.back();
        return;
      }
      taskModule
        .retrieveTaskByTicketNumber(ticketNumber)
        .finally(() => (this.loading = false));
    },
  },
});
</script>

<style></style>
