<template>
  <v-card max-width="620">
    <loginFormComponent />
    <!-- <signupFormComponent /> -->
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import LoginFormComponent from "../subcomponent/auth/loginform-component.vue";
// import SignupFormComponent from "./subcomponent/signupform-component.vue";

export default Vue.extend({
  name: "LoginComponent",
  components: {
    loginFormComponent: LoginFormComponent,
    // signupFormComponent: SignupFormComponent,
  },
});
</script>

<style></style>
