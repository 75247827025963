<template>
  <v-container>
    <apexchart :options="options" :series="series" height="420" />
  </v-container>
</template>

<script lang="ts">
import {
  DonutLabel,
  mapDataKeysToLabel,
} from "../../helpers/statistics-helper";
import {
  SPStageStatistics,
  SPStatistics,
  SPStatusStatistics,
} from "../../model/statistics-model";
import { ApexOptions } from "apexcharts";
import Vue from "vue";
import ApexCharts from "vue-apexcharts";

type PropType = {
  value: SPStageStatistics | SPStatusStatistics;
  label: string;
  labelValues: DonutLabel;
  money: boolean;
};
type DataType = Record<string, string>;
type MethodType = Record<string, string>;
type ComputeType = {
  series: ApexOptions["series"];
  options: ApexOptions;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "DoughnutChartComponent",
  components: {
    apexchart: ApexCharts,
  },
  data: () => ({}),
  props: {
    value: { type: Object, required: true },
    label: { type: String },
    labelValues: { type: Array },
    money: { type: Boolean },
  },
  computed: {
    series() {
      if (this.money) {
        return Object.values(this.value ?? {}).map((x) => x.price);
      }
      return Object.values(this.value ?? {}).map((x) => x.count);
    },

    options() {
      const chartLabels = mapDataKeysToLabel(this.value, this.labelValues);
      return {
        chart: {
          type: "donut",
          height: 350,
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: "gradient",
        },
        title: {
          text: this.label,
          align: "center",
        },
        labels: chartLabels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "top",
                // floating: true,
              },
            },
          },
        ],
        legend: {
          position: "bottom",
          // showForNullSeries: false,
          // showForZeroSeries: false,
          formatter: function (val, opts) {
            const value = opts.w.globals.series[opts.seriesIndex];
            const labelText = chartLabels[opts.seriesIndex];
            return labelText + " - " + value;
          },
        },
      } as ApexOptions;
    },
  },
});
</script>

<style></style>
