<template>
  <span>
    <v-card
      color="transparent"
      flat
      tile
      :max-width="maxWidth"
      class="align-start justify-start rounded-lg"
    >
      <v-card-actions>
        <v-btn rounded text color="primary" left @click="triggerBack">
          <v-icon left large>mdi-chevron-left</v-icon>
          {{ $t("object.label.back") }}
        </v-btn>
        <v-spacer />
        <v-btn fab text small @click="triggerDelete = true" v-if="editInStage">
          <v-icon color="error">mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card class="align-center" :max-width="maxWidth" flat>
        <v-row dense>
          <v-col>
            <v-card-title class="text-start">
              {{ $t("object.allocation.decision") }}
              <statusDisplayComponent
                :color="getInvoiceStage?.color"
                :text="getInvoiceStage?.value"
                class="mx-2"
              />
            </v-card-title>
          </v-col>

          <v-col style="justify-content: center; align-items: center">
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                outlined
                text
                @click="edit"
                v-if="editInStage"
              >
                {{ $t("object.label.edit") }}
                <v-icon right>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                outlined
                v-if="safeStage"
                @click="saveOnly"
              >
                <small> {{ $t("site.btn.save") }} </small>
              </v-btn>
              <v-btn
                color="primary"
                @click="print"
                v-if="invoice.stage !== stages.draft"
              >
                {{ $t("object.invoice.btn.print") }}
                <v-icon right>mdi-printer-outline</v-icon>
              </v-btn>
              <v-btn
                color="success"
                v-if="invoice.stage == stages.save"
                @click="sendInvoice"
              >
                <small> {{ $t("object.invoice.btn.send") }} </small>
              </v-btn>
              <v-btn
                color="success"
                v-if="invoice.stage == stages.draft"
                @click="sendInvoice"
              >
                <small> {{ $t("object.invoice.btn.svNSnd") }} </small>
              </v-btn>
              <v-btn
                color="success"
                v-if="invoice.stage === stages.send"
                @click="markAsPaid"
              >
                <small> {{ $t("object.invoice.btn.markPaid") }} </small>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>

      <v-card max-height="650" class="overflow-y-auto my-4" flat>
        <invoicePdfComponent v-model="invoice" ref="invoiceDocument" />
      </v-card>
    </v-card>
    <deleteComponent
      v-model="triggerDelete"
      @confirm="deleteInvoice"
      @abort="triggerDelete = false"
    />
  </span>
</template>

<script lang="ts">
import { gotoPathQuery, headerEnumFormatter } from "@/helpers/component-helper";
import {
  ComponentPDF,
  createInvoiceObj,
  markInvoiceAsPaid,
  sendInvoice,
} from "@/helpers/invoice-helper";
import { TypeStageFormatter } from "@/model";
import invoiceModule from "@/store/modules/invoice-module";
import { EnumInvoiceStage, Invoice } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import StatusDisplayComponent from "../statusdisplay-component.vue";
import InvoicePdfComponent from "./invoicepdf-component.vue";

const DeleteComponent = () => import("../delete-component.vue");

type PropType = {
  maxWidth: number;
  value: Invoice;
  invoiceStages: TypeStageFormatter<EnumInvoiceStage>[];
};
type ComputeType = {
  invoice: Invoice;
  getInvoiceStage: TypeStageFormatter<EnumInvoiceStage> | null;
  editInStage: boolean;
  loading: boolean;
  safeStage: boolean;
};
type MethodType = {
  queryId(): void;
  edit(): void;
  triggerBack(): void;
  deleteInvoice(): void;
  markAsPaid(): void;
  sendInvoice(): void;
  print(): void;
  saveOnly(): void;
};
type DataType = {
  stages: typeof EnumInvoiceStage;
  triggerDelete: boolean;
};

export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  name: "InvoicePreviewComponent",
  components: {
    invoicePdfComponent: InvoicePdfComponent,
    statusDisplayComponent: StatusDisplayComponent,
    deleteComponent: DeleteComponent,
  },
  data: () => ({
    stages: EnumInvoiceStage,
    triggerDelete: false,
  }),
  props: {
    maxWidth: { type: Number, default: 820 },
    value: { type: Invoice },
    invoiceStages: { type: Array },
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.isLocalLoading;
      },
      set(value) {
        this.$store.commit("setLocalLoading", value);
      },
    },
    invoice: {
      get() {
        return invoiceModule.invoice;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
    getInvoiceStage() {
      const stage = headerEnumFormatter(this.invoice.stage, this.invoiceStages);
      return stage;
    },
    editInStage() {
      if (this.invoice && this.invoice.stage) {
        return this.invoice.stage < this.stages.send;
      }
      return true;
    },
    safeStage() {
      if (this.invoice && this.invoice.stage) {
        return this.invoice.stage < this.stages.save;
      }
      return false;
    },
  },
  mounted() {
    this.queryId();
  },
  methods: {
    queryId() {
      const invoiceNumber = (this.$route.query.invoiceNumber ?? "") as string;
      this.loading = true;
      if (!invoiceNumber) {
        this.loading = false;
        return;
      }
      invoiceModule
        .retrieveInvoiceByInvNumber(invoiceNumber)
        .then(() => {
          this.invoice = invoiceModule.invoice;
        })
        .finally(() => (this.loading = false));
    },
    edit() {
      gotoPathQuery(this.$route, this.$router, { mode: "edit" });
      this.$emit("editInvoice", this.invoice);
    },
    triggerBack() {
      this.$emit("triggerBack");
      this.$router.back();
    },
    deleteInvoice() {
      this.$emit("delete");
    },
    markAsPaid() {
      this.loading = true;
      markInvoiceAsPaid(this.invoice, () => {
        this.loading = false;
        this.$emit("paid");
      });
    },
    sendInvoice() {
      const component = this.$refs.invoiceDocument as Vue;
      this.invoice.file = component.$el.innerHTML;
      this.loading = true;
      sendInvoice(this.invoice, () => {
        this.$emit("send");
        this.loading = false;
      });
    },
    print() {
      this.loading = true;
      const component = this.$refs.invoiceDocument as Vue;
      new ComponentPDF(component)
        .printPDF()
        .finally(() => (this.loading = false));
    },
    saveOnly() {
      this.loading = true;
      createInvoiceObj(this.invoice, () => {
        this.loading = false;
        this.$emit("save");
      });
    },
  },
});
</script>
