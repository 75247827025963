import serviceproviderModule from "@/store/modules/serviceprovider-module";
import { EnumBoolean, ServiceProvider } from "@taskmanagement/taskapp-model";

const sp = new ServiceProvider();
export function deactivateSP(id: number) {
  const localSP = sp;
  localSP.id = id;
  localSP.active = EnumBoolean.false;
  return serviceproviderModule.updateSP(localSP);
}

export function activateSP(id: number) {
  const localSP = sp;
  localSP.id = id;
  localSP.active = EnumBoolean.true;
  return serviceproviderModule.updateSP(localSP);
}
