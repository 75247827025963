import { QueryParams } from "@/model";
import { Task } from "@taskmanagement/taskapp-model";
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import store from "..";
import { deleteFromArray, updateArray } from "../../helpers/component-helper";
import { TaskService } from "../../services/task-service";

@Module({
  name: "task",
  store,
  dynamic: true,
})
class TaskModule extends VuexModule {
  taskList: Task[] = new Array<Task>();
  task = {} as Task;

  @Action({ commit: "getTaskList" })
  retriveTaskList(params?: QueryParams<Task>) {
    return new TaskService().getAll(params);
  }

  @Action({ commit: "getTask" })
  retrieveTask(data: number | string) {
    const task = new Task();
    task.id = data as number;
    return new TaskService().getOne(task);
  }

  @Action({ commit: "getTask" })
  retrieveTaskByTicketNumber(ticketNumber: string) {
    return new TaskService().getOneByTicketNumber(ticketNumber);
  }

  @Action({ commit: "createNewTask" })
  createTask(task: Task): Promise<Task | undefined> {
    return new TaskService().create(task).then((val) => {
      if (val) return val;
    });
  }

  @Action({ commit: "updateTaskList" })
  updateTask(task: Task) {
    return new TaskService().update(task).then((val) => {
      if (val) {
        return task;
      }
      return;
    });
  }

  @Action({ commit: "trashTask" })
  deleteTask(task: Task) {
    return new TaskService().delete(task).then((tsk) => {
      if (tsk) return task;
    });
  }

  @Mutation
  getTask(task: Task) {
    if (task && task.id) {
      this.task = new Task();
      this.task = task;
    }
  }

  @Mutation
  getTaskList(taskList: Task[]) {
    if (!taskList) return;
    Promise.resolve(taskList)
      .then((list) => {
        this.taskList = [];
        return list;
      })
      .then((tasks) => {
        this.taskList = tasks;
      });
  }

  @Mutation
  createNewTask(task: Task | undefined) {
    if (task && task.id) return this.taskList.push(task);
  }

  @Mutation
  updateTaskList(task: Task | undefined) {
    if (task) {
      this.taskList = updateArray(this.taskList, task.id, task);
    }
  }

  @Mutation
  trashTask(task: Task | undefined) {
    if (task) {
      this.taskList = deleteFromArray(this.taskList, task);
    }
  }
}

export default getModule(TaskModule);
