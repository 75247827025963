<template>
  <span>
    <newServProviderComponent
      v-if="newMode"
      class="my-2"
      @submit="onFormSubmit"
    />

    <serviceProvidertableComponent
      v-else
      @new="triggerNew"
      :items="tableItems"
      @editItem="triggerEdit"
      @deleteItem="triggerDelete"
    />

    <deleteComponent
      v-model="deleteDialog"
      @confirm="onConfirmDelete"
      @abort="onCancelDelete"
    />

    <local-loader :value="loading" :label="loadingMsg" />
  </span>
</template>

<script lang="ts">
import { gotoPathQuery } from "@/helpers/component-helper";
import { ServiceProvider } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { i18n } from "../../../plugins/i18n";
import serviceproviderModule from "../../../store/modules/serviceprovider-module";
import DeleteComponent from "../delete-component.vue";
import NewServProviderComponent from "./newservprovider-component.vue";

import ServiceProviderTableComponent from "./serviceprovidertable-component.vue";

export default Vue.extend({
  name: "ServiceProviderComponent",
  data: () => ({
    serviceProvider: new ServiceProvider(),
    deleteDialog: false,
    loading: false,
    loadingMsg: null as string | null,
  }),
  components: {
    serviceProvidertableComponent: ServiceProviderTableComponent,
    deleteComponent: DeleteComponent,
    newServProviderComponent: NewServProviderComponent,
  },
  computed: {
    headerText() {
      if (this.serviceProvider.id) {
        return i18n.t("object.serviceProvider.editHeaderTxt").toString();
      }
      return i18n.t("object.serviceProvider.newHeaderTxt").toString();
    },
    tableItems() {
      return serviceproviderModule.allServiceProviders;
    },
    newMode() {
      return this.$route.query.mode === "new";
    },
  },
  methods: {
    triggerNew() {
      gotoPathQuery(this.$route, this.$router, { mode: "new" });
    },
    triggerEdit(item: ServiceProvider) {
      this.serviceProvider = item;
    },
    triggerDelete(item: ServiceProvider) {
      this.serviceProvider = item;
      this.deleteDialog = true;
    },
    onFormClose() {
      this.serviceProvider = new ServiceProvider();
    },
    onFormSubmit(item: ServiceProvider) {
      if (item) {
        this.loadingMsg = i18n.t("site.loader.loadingMsg.saving").toString();
        this.loading = true;
        if (item.id) {
          this.submitOldEntry(item);
        } else {
          this.submitNewEntry(item);
        }
      }
    },
    submitOldEntry(item: ServiceProvider) {
      serviceproviderModule.updateSP(item).finally(() => {
        this.loading = false;
        this.loadingMsg = null;
        this.serviceProvider = new ServiceProvider();
      });
    },
    submitNewEntry(item: ServiceProvider) {
      serviceproviderModule.createSP(item).finally(() => {
        this.loading = false;
        this.loadingMsg = null;
        this.serviceProvider = new ServiceProvider();
      });
    },
    onConfirmDelete() {
      if (this.serviceProvider) {
        this.loadingMsg = "Deleting...";
        this.deleteDialog = false;
        this.loading = true;
        serviceproviderModule.deleteSP(this.serviceProvider).finally(() => {
          this.loading = false;
          this.loadingMsg = null;
          this.serviceProvider = new ServiceProvider();
        });
      }
    },
    onCancelDelete() {
      this.serviceProvider = new ServiceProvider();
      this.deleteDialog = false;
    },
  },
});
</script>

<style></style>
