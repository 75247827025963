<template>
  <v-dialog v-model="dialog" width="500">
    <v-card rounded="lg">
      <v-card-title>
        <strong> {{ $t("object.task.comment") }} </strong>
      </v-card-title>

      <v-divider class="mx-4" />

      <v-textarea
        v-model="comment"
        outlined
        filled
        rows="2"
        persistent-hint
        hide-spin-buttons
        class="mx-4"
        type="number"
      />

      <v-card-actions class="justify-space-between mt-4">
        <v-btn color="primary" large outlined @click="abort">
          {{ $t("site.btn.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" large @click="submit">
          {{ $t("site.btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";

type DataType = { comment: null | number };
type ComputedType = { dialog: boolean };
type MethodType = { submit(): void; abort(): void };
type PropType = { value: boolean };

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "ApprovalCancelComponent",
  data: () => ({
    comment: null,
  }),
  props: {
    value: { type: Boolean },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submit", this.comment);
    },
    abort() {
      this.$emit("abort");
    },
  },
});
</script>
