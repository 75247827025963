<template>
  <v-card class="mx-auto my-12 rounded-xl" max-width="374" elevation="16">
    <v-card-text>
      <v-row align="center" justify="center" class="fill-height">
        <v-avatar size="200" style="height: 100%">
          <v-icon :color="color" x-large>{{ icon }}</v-icon>
        </v-avatar>
      </v-row>
    </v-card-text>
    {{ title }}
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
  </v-card>
</template>

<script lang="ts">
import { TypeRequest } from "@/model";
import { i18n } from "../../plugins/i18n";
import Vue from "vue";
import { PropValidator } from "vue/types/options";

export default Vue.extend({
  name: "RequestResponseComponent",
  components: {},
  data: () => ({}),
  props: {
    requestType: {
      type: String,
      required: true,
    } as PropValidator<TypeRequest>,
    title: { type: String, default: "No Access" },
    subtitle: {
      type: String,
      default: i18n.t("site.response.defaultSubTitle").toString(),
    },
  },
  computed: {
    icon() {
      if (this.requestType === "success") {
        return "mdi-check-all";
      } else {
        return "mdi-close-circle";
      }
    },
    color() {
      if (this.requestType == "success") {
        return "success";
      } else {
        return "error";
      }
    },
  },
});
</script>

<style></style>
