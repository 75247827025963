import { StatusStatistics } from "@taskmanagement/taskapp-model";
import { ApexOptions } from "apexcharts";
import { DataTableHeader } from "vuetify";
import {
  SPStageStatistics,
  SPStatistics,
  SPStatisticsList,
  SPStatusStatistics,
} from "../model/statistics-model";
import { sumValues } from "./component-helper";

export type DonutLabel = LocalLabel<StatusStatistics<number>>[];

export function getFullName(item: SPStatistics) {
  return `${item.subject.firstName} ${item.subject.lastName}`;
}

export function transformStat(originalData: SPStatistics[]) {
  return originalData.map((item) => ({
    subject: item.subject,
    totalTasks: item.totalTasks,
    ...item.stages,
    ...item.status,
  }));
}

export const statisticsHeader: DataTableHeader[] = [
  { text: "Service Provider", value: "subject" },
  { text: "No. of Declined Tasks", value: "declined" },
  { text: "No. of Tasks In progress", value: "inProgress" },
  { text: "Accepted Tasks", value: "accepted" },
  { text: "Tasks Finished", value: "finished" },
  { text: "Payment Received", value: "paidTasks" },
  { text: "Total No of Tasks", value: "totalTasks" },
  { text: "", value: "actions", sortable: false },
];

export function getSPTasksCategories(item: SPStatistics) {
  const stage = item.stages;
  const decision = item.status;
  const declined = decision?.numberOfDeclined?.count;
  const inProgress = sumValues(
    stage?.numberOfInProgressTask?.count,
    stage?.numberOfCreatedTask?.count
  ) as number | undefined;
  const accepted = decision?.numberOfAccepted?.count;
  const finished = stage?.numberOfDoneTask?.count;
  const paidTasks = stage?.numberOfPaymentCompleteTask?.count;

  return {
    declined,
    inProgress,
    accepted,
    finished,
    paidTasks,
  };
}
type StageKeys = keyof NonNullable<SPStatistics["stages"]>;
export function createArrayOfStageProps(
  x: SPStatistics[],
  stageVal: StageKeys
): number[] {
  return x.flatMap((x) => x.stages?.[stageVal]?.count ?? []);
}

type StatusKeys = keyof NonNullable<SPStatistics["status"]>;
export function createArrayOfStatusCount(
  x: SPStatistics[],
  statusVal: StatusKeys
): number[] {
  return x.flatMap((x) => x.status?.[statusVal]?.count ?? []);
}

export function createArrayOfStatusPrice(
  x: SPStatistics[],
  statusVal: StatusKeys
): number[] {
  return x.flatMap((x) => x.status?.[statusVal]?.price ?? []);
}

export function createArrayOfStagePrice(
  x: SPStatistics[],
  statusVal: StageKeys
): number[] {
  return x.flatMap((x) => x.stages?.[statusVal]?.price ?? []);
}

export function getBarChartCountSeries(
  array: SPStatisticsList | undefined
): ApexOptions["series"] {
  if (array) {
    return [
      {
        name: "No. of Accepted",
        data: createArrayOfStatusCount(array, "numberOfAccepted"),
      },
      {
        name: "No. of Declined",
        data: createArrayOfStatusCount(array, "numberOfDeclined"),
      },
      {
        name: "No. of UnAnswered",
        data: createArrayOfStatusCount(array, "numberofNullStatus"),
      },
      // {
      //   name: "Accepted",
      //   data: createArrayOfStatusPrice(array, "numberOfAccepted"),
      //   group: "price",
      //   // color: colors.green.base,
      // },
      // {
      //   name: "Declined",
      //   data: createArrayOfStatusPrice(array, "numberOfDeclined"),
      //   group: "price",
      //   // color: colors.red.base,
      // },
      // {
      //   name: "UnAnswered",
      //   data: createArrayOfStatusPrice(array, "numberofNullStatus"),
      //   group: "price",
      //   // color: colors.yellow.base,
      // },
    ];
  }
  return [];
}

export function getBarChartPriceSeries(
  array: SPStatisticsList | undefined
): ApexOptions["series"] {
  if (array) {
    return [
      {
        name: "In Progress",
        data: createArrayOfStagePrice(array, "numberOfInProgressTask"),
      },
      {
        name: "Completed",
        data: createArrayOfStagePrice(array, "numberOfDoneTask"),
      },
      {
        name: "Payment Started",
        data: createArrayOfStagePrice(array, "numberOfPaymentStartedTask"),
      },
      {
        name: "Payment Received",
        data: createArrayOfStagePrice(array, "numberOfPaymentCompleteTask"),
      },
    ];
  }
  return [];
}

export type LocalLabel<T> = { text: string; value: keyof T };

export const stagesLabel: LocalLabel<NonNullable<SPStatistics["stages"]>>[] = [
  { value: "numberOfAcceptedTasks", text: "Accepted" },
  { value: "numberOfCreatedTask", text: "Created" },
  { value: "numberOfDeclinedTask", text: "Declined" },
  { value: "numberOfDoneTask", text: "Done" },
  { value: "numberOfInProgressTask", text: "In Progress" },
  { value: "numberOfNullTasks", text: "Non" },
  { value: "numberOfPaymentCompleteTask", text: "Payment Completed" },
  { value: "numberOfPaymentStartedTask", text: "Payment Ongoing" },
  { value: "numberOfViewedTask", text: "Viewed, Awaiting decision" },
];
export const statusLabel: LocalLabel<NonNullable<SPStatistics["status"]>>[] = [
  { value: "numberOfAccepted", text: "Accepted" },
  { value: "numberOfBadRequests", text: "Wrongly sent" },
  { value: "numberOfDeclined", text: "Declined" },
  { value: "numberofNullStatus", text: "Awaiting Decision" },
];

export function mapDataKeysToLabel(
  data: SPStageStatistics | SPStatusStatistics,
  array: DonutLabel
) {
  return Object.keys(data ?? {}).map((key) => {
    const labelObject = array.find((label) => label.value === key);
    return labelObject?.text ?? key;
  });
}

export function extractWordFromObjectKey(str: string) {
  let result = str?.replace(/numberOf/i, "");
  result = result?.replace(/Task(s)?$/i, "");
  return result;
}

export function getSPListFromStatistics(array: SPStatisticsList | undefined) {
  if (array) {
    return array.map((x) => getFullName(x));
  }
  return [];
}

export function formatDonutDisplay(
  ref: SPStatistics["stages"] | SPStatistics["status"],
  val: string,
  opts: any
) {
  const stages = ref ?? {};
  const keys = Object.keys(stages) as Array<keyof typeof stages>;
  const key = keys[opts.seriesIndex];
  const value = stages[key];
  return extractWordFromObjectKey(String(key)) + " - " + value;
}
