import Vue from "vue";
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  position: POSITION.BOTTOM_CENTER,
  duration: 5000,
  dismissible: true,
  closeOnClick: true,
  maxToast: 3,
  transition: "Vue-Toastification__fade",
  hideProgressBar: true,
};

Vue.use(Toast, options);
