<template>
  <strong :class="textColor + '--text'">
    {{ prettyTime }}
  </strong>
</template>

<script lang="ts">
import { formatTime } from "@/helpers/component-helper";
import { allowedTime } from "@taskmanagement/taskapp-model";
import moment, { Moment } from "moment";
import Vue from "vue";

type DataType = {
  isRunning: boolean;
  minutes: number;
  secondes: number;
  // eslint-disable-next-line no-undef
  timer: NodeJS.Timeout | null;
  time: number;
};
type ComputedType = {
  prettyTime: string;
  elasped: boolean;
  allowedTime: Moment;
};
type PropType = { refTime: string; textColor: string; color: string };
type MethodType = {
  start(): void;
  stop(): void;
  reset(): void;
  formatTime(time: string): string;
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "CountDownTimerComponent",
  data: () => ({
    isRunning: false,
    minutes: 0,
    secondes: 0,
    // eslint-disable-next-line no-undef
    timer: null as NodeJS.Timeout | null,
    time: 0,
  }),
  computed: {
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time.toString());
      let secondes = Math.round((time - minutes) * 60);
      return `${minutes}:${secondes < 10 ? "0" : ""}${secondes}`;
    },
    elasped() {
      return moment(this.refTime).isBefore(moment());
    },
    allowedTime() {
      const expiryTime = moment(this.refTime).add(allowedTime, "minutes");
      return expiryTime;
    },
  },
  props: {
    refTime: { type: String, required: true },
    textColor: { type: String, default: "error" },
    color: { type: String, default: "error" },
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      // Calculate initial time remaining based on refTime and current time
      const now = moment();
      const allowedTime = moment(this.allowedTime);
      const diffSeconds = allowedTime.diff(now, "seconds");

      // Check if the refTime has already passed
      if (diffSeconds <= 0) {
        this.time = 0;
        this.isRunning = false;
        return;
      }

      this.time = diffSeconds;

      // Start the countdown timer
      this.isRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            // eslint-disable-next-line no-undef
            clearInterval(this.timer as NodeJS.Timeout);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isRunning = false;
      // eslint-disable-next-line no-undef
      clearInterval(this.timer as NodeJS.Timeout);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.time = 0;
      this.secondes = 0;
      this.minutes = 0;
    },
    formatTime(time: string) {
      return formatTime(time, "DD-MM-YYYY HH:mm", this.$route.params.locale);
    },
  },
});
</script>

<style></style>
