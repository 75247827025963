import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";

import AppBarComponent from "../layout/appbar-component.vue";
import BottomNavigation from "../layout/bottomnavigation-component.vue";
import MainComponent from "../layout/main-component.vue";
import NavbarComponent from "../layout/navigation-component.vue";

import AdminView from "../views/AdminView.vue";
import DashboardView from "../views/DashboardView.vue";
import HomeView from "../views/HomeView.vue";
import InvoiceDetailView from "../views/InvoiceDetailView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import OrderDetailsView from "../views/OrderDetailsView.vue";
import ApprovalView from "../views/approval-view.vue";
import AuthView from "../views/auth-view.vue";
import StatisticsView from "../views/statistics-view.vue";

import {
  convertLocaleToUrlLocale,
  defaultLanguage,
  getBrowserMainLanguage,
  getUrlLocaleToLocale,
  i18n,
  loadLanguageAsync,
  storageLangKey,
} from "./i18n";

import { setCookies } from "@/helpers/cookies-helper";
import LoadingComponent from "@/layout/loading-component.vue";
import LocalLoadingComponent from "@/layout/localLoading-component.vue";
import ServiceProviderProfileView from "@/views/ServiceProviderProfileView.vue";
import { catClient } from "../helpers/logging";
import { appName } from "../model/config";
import authModule from "../store/modules/auth-module";

export const ROUTE_HOME = "home";
export const ROUTE_ADMIN = "admin";
export const ROUTE_DASHBOARD = "dashboard";
export const ROUTE_ORDER = "order";
export const ROUTE_REQUESTER = "requester";
export const ROUTE_APPROVAL = "approval";
export const ROUTE_LOGIN = "login";
export const ROUTE_ORDERDETAILS = "orderdetail";
export const ROUTE_INVOICEDETAILS = "invoicedetail";
export const ROUTE_STATISTICS = "statistics";
export const ROUTE_INVOICE = "invoice";
export const ROUTE_WORKER_PROFILE = "serviceproviderprofile";

const titlePrefix = appName + "-";

const DEFAULT_ROUTE = ROUTE_HOME;
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    component: AuthView,
  },
  {
    path: "/:locale",
    components: {
      default: MainComponent,
      navigation: NavbarComponent,
      appbar: AppBarComponent,
      loader: LoadingComponent,
      bottomnav: BottomNavigation,
      localLoader: LocalLoadingComponent,
    },
    children: [
      {
        path: "",
        name: "",
        redirect: {
          name: DEFAULT_ROUTE,
        },
      },
      {
        path: ROUTE_LOGIN,
        name: ROUTE_LOGIN,
        component: AuthView,
        meta: {
          doLogin: true,
          nav: {
            icon: "mdi-login",
            title: () => i18n.t("site.route.login"),
          },
        },
      },
      {
        path: ROUTE_HOME,
        name: ROUTE_HOME,
        component: HomeView,
        meta: {
          isHidden: true,
          requireLogin: false,
          nav: {
            icon: "mdi-home",
            title: () => i18n.t("site.route.home"),
          },
        },
      },
      {
        path: ROUTE_ORDER,
        name: ROUTE_ORDER,
        component: DashboardView,
        meta: {
          requireLogin: true,
          nav: {
            icon: "mdi-view-dashboard-outline",
            title: () => i18n.t("object.task.listName"),
          },
        },
      },
      {
        path: `${ROUTE_ORDER}/:ticketNumber`,
        name: ROUTE_ORDERDETAILS,
        component: OrderDetailsView,
        meta: {
          isHidden: true,
          requireLogin: true,
          nav: {
            title: () => i18n.t("object.task.orderName"),
          },
        },
      },
      {
        path: ROUTE_STATISTICS,
        name: ROUTE_STATISTICS,
        component: StatisticsView,
        meta: {
          requireLogin: true,
          nav: {
            icon: "mdi-chart-bar",
            title: () => i18n.t("site.route.statistics"),
          },
        },
      },
      {
        path: ROUTE_INVOICE,
        name: ROUTE_INVOICE,
        component: InvoiceView,
        meta: {
          requireLogin: true,
          nav: {
            icon: "mdi-file-document-outline",
            title: () => i18n.t("site.route.invoice"),
          },
        },
      },
      {
        path: `${ROUTE_INVOICE}/:invoiceNumber`,
        name: ROUTE_INVOICEDETAILS,
        component: InvoiceDetailView,
        meta: {
          requireLogin: true,
          isHidden: true,
        },
      },
      {
        path: ROUTE_ADMIN,
        name: ROUTE_ADMIN,
        component: AdminView,
        meta: {
          requireLogin: true,
          nav: {
            icon: "mdi-account-circle-outline",
            title: () => i18n.t("site.route.admin"),
          },
        },
      },
      {
        path: `${ROUTE_ADMIN}/:id`,
        name: ROUTE_WORKER_PROFILE,
        component: ServiceProviderProfileView,
        meta: {
          requireLogin: true,
        },
      },
      {
        path: ROUTE_APPROVAL,
        name: ROUTE_APPROVAL,
        component: ApprovalView,
        meta: {
          isHidden: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach((to, _from, next) => {
  const localeFromRoute =
    to.params.locale ?? convertLocaleToUrlLocale(getBrowserMainLanguage());

  const locale = getUrlLocaleToLocale(localeFromRoute) ?? defaultLanguage;

  setCookies(storageLangKey, locale);

  if (i18n.locale !== locale) {
    loadLanguageAsync(locale)
      .then(() => executeRouterBeforeEach(locale, to, localeFromRoute, next))
      .catch((error) =>
        catClient.error("Error before route execution" + error)
      );
  } else {
    executeRouterBeforeEach(locale, to, localeFromRoute, next);
  }
});

router.afterEach((to) => {
  // UseNext tick to handle router history correctly
  Vue.nextTick(() => {
    // eslint-disable-next-line prettier/prettier
    document.title = to.meta?.title ?? (to.meta?.nav?.title() ? titlePrefix + to.meta?.nav?.title() : DEFAULT_ROUTE);
  });
});

// eslint-disable-next-line prettier/prettier
function executeRouterBeforeEach(locale: string, to: Route, localeFromRoute: string, next: NavigationGuardNext<Vue>): void {
  // except token from Locale check
  if (
    !to.meta?.useNoLocaleCheck &&
    convertLocaleToUrlLocale(locale) !== to.params.locale
  ) {
    if (to.params.locale !== undefined) {
      Vue.$toast.error(
        `The selected language ${localeFromRoute} is not supported`
      );
    }
    next({
      name: to.name ?? DEFAULT_ROUTE,
      params: { locale: convertLocaleToUrlLocale(locale) },
    });
  } else if (to.matched.some((record) => record.meta.doLogin)) {
    if (authModule.isLoggedIn) {
      next({
        name: DEFAULT_ROUTE,
        params: to.params,
        query: to.query,
      });
    }
  } else if (to.matched.some((record) => record.meta.requireLogin)) {
    if (!authModule.isLoggedIn) {
      next({
        name: ROUTE_LOGIN,
        params: to.params,
        query: {
          ...to.query,
          redirect_url: to.path,
        },
      });
    } else {
      next();
    }
  } else {
    if (
      to.matched.length === 0 ||
      to.matched.some(
        (x) =>
          x.parent !== undefined &&
          x.components.default === undefined &&
          x.redirect === undefined
      )
    ) {
      catClient.error(`Route ${to.path} not found!`, null);
      Vue.$toast.error(`The given site ${to.params.pathMatch ?? to.path}`);
      next({ name: DEFAULT_ROUTE });
    }
  }
  next();
}

export function isDefaultLanguageInRoute(): boolean {
  return (
    router.currentRoute.params.locale ==
    convertLocaleToUrlLocale(defaultLanguage)
  );
}
