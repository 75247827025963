export const appName = "TaskDispatch";

export const headerAuthorization = "authorization";

export const headerAcceptLanguage = "accept-language";

export const tablePrefix = "tbl_";

export const ticketPrefix = "AB-";

export const API_URL = process.env.API_URL;
